import ReactDOM from "react-dom/client";
// Importing styles
import "./styles/index.scss";

// REACT ROUTER
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// REDUX TOOLKIT STORE
import AuthenticationCheck from "./AuthenticationCheck";
import store from "./redux/store";

// CUSTOM COMPONENTS

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
/* if (process.env.NODE_ENV == 'production')
		  console.log = () => {}; */

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <AuthenticationCheck />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
