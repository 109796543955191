import { createAsyncThunk } from "@reduxjs/toolkit";

import { Services } from "../../services";

export const fetchRegularFollowUpTasksInfo = createAsyncThunk(
  "regularFollowUpTasksInfo/home",
  async () => {
    try {
      return Services.followUpService.getRegularFollowUpTasksInfo();
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchFollowUpTaskInfo = createAsyncThunk(
  "followUpTasksInfo/home",
  async ({ taskId }: { taskId: string }) => {
    try {
      return Services.followUpService.getFollowUpTaskInfo(taskId);
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchFollowUpProfileOtherInfo = createAsyncThunk(
  "fetchFollowUpUserDetails/home",
  async ({ userId }: { userId: string }) => {
    try {
      return Services.followUpService.getProfileOtherInfo(userId);
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchPlacementDetails = createAsyncThunk(
  "fetchPlacementDetails/home",
  async ({ userId }: { userId: string }) => {
    try {
      return Services.followUpService.getPlacementDetails(userId);
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchCourseDetails = createAsyncThunk(
  "fetchCourseDetails/home",
  async ({ userId }: { userId: string }) => {
    try {
      const res = await Promise.allSettled([
        Services.followUpService.getCourseDetailsForLatestModule(userId),
        Services.followUpService.getCourseDetailsForGymDetails(userId),
        Services.followUpService.getUserMocks(userId),
      ]);

      let latestModule = null;
      let gymDetails = null;
      let mocks = null;
      if (res[0].status === "fulfilled") {
        latestModule = res[0].value;
      }
      if (res[1].status === "fulfilled") {
        gymDetails = res[1].value;
      }
      if (res[2].status === "fulfilled") {
        mocks = res[2].value;
      }

      return { latestModule, gymDetails, mocks };
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchAdhocTasks = createAsyncThunk(
  "fetchAdhocTasks/home",
  async () => {
    try {
      return Services.followUpService.getAdhocTasks();
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchTodaysFollowUpTaskCount = createAsyncThunk(
  "fetchTodaysFollowUpTaskCount/home",
  async () => {
    try {
      const count = await Services.followUpService.getTodaysFollowUpCount();
      return { count };
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchBmTaskDetailsData = createAsyncThunk(
  "fetchBmTaskDetailsData/home",
  async ({ taskId }: { taskId: string }) => {
    try {
      return Services.homeService.getBmTaskDetailsData({ taskId });
    } catch (error) {
      console.log(error);
    }
  }
);
