import { singleton } from "../utils/singleton";

import { ApiFailureErrorMessage, HttpService } from "./http.service";

@singleton
export class HomeService {
  private endpoint = process.env.REACT_APP_ROOT;
  private httpService = new HttpService();

  async getBatchManagerInfo() {
    const url = `${this.endpoint}/batch-manager-crm/batch-manager-info`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getBmTasksData({ bmId }: { bmId: string }) {
    const url = `${this.endpoint}/batch-manager-crm/homepage/tasks/${bmId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getBmAllTasksData({ bmId }: { bmId: string }) {
    const url = `${this.endpoint}/batch-manager-crm/tasks/${bmId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getBmTaskDetailsData({ taskId }: { taskId: string }) {
    const url = `${this.endpoint}/batch-manager-crm/task/${taskId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }
}
