import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAdhocTasks,
  fetchBmTaskDetailsData,
  fetchCourseDetails,
  fetchFollowUpProfileOtherInfo,
  fetchFollowUpTaskInfo,
  fetchPlacementDetails,
  fetchRegularFollowUpTasksInfo,
  fetchTodaysFollowUpTaskCount,
} from "./followUp.thunk";
import { FollowUpInterface } from "./followUp.type";

const initialState: FollowUpInterface = {
  regularFollowUpTasksInfo: [],
  followUpTasksInfo: null,
  followUpPageLoading: false,
  followUpPageError: null,
  otherInfo: null,
  placementDetails: null,
  adhocTasks: [],
  todaysFollowUpTaskCount: 0,
};

export const FollowUp = createSlice({
  name: "followUp",
  initialState,
  reducers: {},
  extraReducers: {
    // Batch Regular FollowUp Tasks Info
    [fetchRegularFollowUpTasksInfo.pending.type]: (
      state,
      action: ReturnType<typeof fetchRegularFollowUpTasksInfo.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchRegularFollowUpTasksInfo.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchRegularFollowUpTasksInfo.fulfilled>
    ) => {
      state.regularFollowUpTasksInfo = action.payload.map((task: any) => {
        task.id = task.userId;
        task.currentStatus = task.currentStatus;
        return task;
      });
      state.followUpPageLoading = false;
    },
    [fetchRegularFollowUpTasksInfo.rejected.type]: (
      state,
      action: ReturnType<typeof fetchRegularFollowUpTasksInfo.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "Regular FollowUp Tasks Info Fetch Failed",
      };
    },

    // Batch FollowUp Tasks Info
    [fetchFollowUpTaskInfo.pending.type]: (
      state,
      action: ReturnType<typeof fetchFollowUpTaskInfo.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchFollowUpTaskInfo.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchFollowUpTaskInfo.fulfilled>
    ) => {
      state.followUpTasksInfo = action.payload;
      if (state.followUpTasksInfo?.studentData)
        state.followUpTasksInfo.studentData = action.payload?.studentData?.map(
          (task: any) => {
            task.id = task.userId;
            return task;
          }
        );
      state.followUpPageLoading = false;
    },
    [fetchFollowUpTaskInfo.rejected.type]: (
      state,
      action: ReturnType<typeof fetchFollowUpTaskInfo.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "FollowUp Tasks Info Fetch Failed",
      };
    },

    // Batch FollowUp Other Info
    [fetchFollowUpProfileOtherInfo.pending.type]: (
      state,
      action: ReturnType<typeof fetchFollowUpProfileOtherInfo.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchFollowUpProfileOtherInfo.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchFollowUpProfileOtherInfo.fulfilled>
    ) => {
      state.followUpTasksInfo = action.payload;
      state.followUpPageLoading = false;
    },
    [fetchFollowUpProfileOtherInfo.rejected.type]: (
      state,
      action: ReturnType<typeof fetchFollowUpProfileOtherInfo.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "FollowUp Tasks Other Info Fetch Failed",
      };
    },

    // Batch FollowUp Placement Details
    [fetchPlacementDetails.pending.type]: (
      state,
      action: ReturnType<typeof fetchPlacementDetails.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchPlacementDetails.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchPlacementDetails.fulfilled>
    ) => {
      state.placementDetails = action.payload;
      state.placementDetails!.jobCardsForUser =
        action.payload?.jobCardsForUser?.map((task: any) => {
          task.id = task.companyName;
          return task;
        });
      state.placementDetails!.placementReadyMocks =
        action.payload?.placementReadyMocks?.map((task: any) => {
          task.id = task.date;
          return task;
        });
      state.followUpPageLoading = false;
    },
    [fetchPlacementDetails.rejected.type]: (
      state,
      action: ReturnType<typeof fetchPlacementDetails.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "FollowUp Tasks Placement Details Fetch Failed",
      };
    },

    // Batch FollowUp Course Details
    [fetchCourseDetails.pending.type]: (
      state,
      action: ReturnType<typeof fetchCourseDetails.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchCourseDetails.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchCourseDetails.fulfilled>
    ) => {
      state.courseDetails = action.payload;
      state.followUpPageLoading = false;
    },
    [fetchCourseDetails.rejected.type]: (
      state,
      action: ReturnType<typeof fetchCourseDetails.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "FollowUp Tasks Course Details Fetch Failed",
      };
    },

    [fetchAdhocTasks.pending.type]: (
      state,
      action: ReturnType<typeof fetchAdhocTasks.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchAdhocTasks.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchAdhocTasks.fulfilled>
    ) => {
      state.adhocTasks = action.payload;
      state.followUpPageLoading = false;
    },
    [fetchAdhocTasks.rejected.type]: (
      state,
      action: ReturnType<typeof fetchAdhocTasks.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "Adhoc Tasks Fetch Failed",
      };
    },

    // Batch FollowUp Todays FollowUp Task Count
    [fetchTodaysFollowUpTaskCount.pending.type]: (
      state,
      action: ReturnType<typeof fetchTodaysFollowUpTaskCount.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchTodaysFollowUpTaskCount.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchTodaysFollowUpTaskCount.fulfilled>
    ) => {
      state.todaysFollowUpTaskCount = action.payload?.count;
      state.followUpPageLoading = false;
    },
    [fetchTodaysFollowUpTaskCount.rejected.type]: (
      state,
      action: ReturnType<typeof fetchTodaysFollowUpTaskCount.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "Todays FollowUp Task Count Fetch Failed",
      };
    },

    [fetchBmTaskDetailsData.pending.type]: (
      state,
      action: ReturnType<typeof fetchBmTaskDetailsData.pending>
    ) => {
      state.followUpPageLoading = true;
    },
    [fetchBmTaskDetailsData.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchBmTaskDetailsData.fulfilled>
    ) => {
      state.taskDetails = action.payload;
      state.followUpPageLoading = false;
    },
    [fetchBmTaskDetailsData.rejected.type]: (
      state,
      action: ReturnType<typeof fetchBmTaskDetailsData.rejected>
    ) => {
      state.followUpPageLoading = false;
      state.followUpPageError = {
        error: action.payload,
        message: "Bm Task Details Data Fetch Failed",
      };
    },
  },
});

export const FollowUpActions = FollowUp.actions;
export const FollowUpReducer = FollowUp.reducer;
