import { createAsyncThunk } from "@reduxjs/toolkit";

import { Services } from "../../services";

export const fetchCourseList = createAsyncThunk("users/enrollment", () => {
  try {
    /* const res = {};
			   let { data } = await services.courseService.getAllCourseData(); */
  } catch (error) {
    console.error(error);
  }
});

export const fetchUserReport = createAsyncThunk("users/getReport", async () => {
  try {
    return Services.userService.getUser();
  } catch (error) {
    console.error(error);
  }
});
