import { FC } from "react";
import { FaUserCircle } from "react-icons/fa";

export type StudentPersonalDetailsState = {
  name: string;
  email: string;
  phone: string;
  currentLocation: string;
  latestCourse: string;
  batchAge: string;
};

type StudentPersonalDetailsProps = {
  studentPersonalDetails?: StudentPersonalDetailsState;
};

const StudentPersonalDetails: FC<StudentPersonalDetailsProps> = ({
  studentPersonalDetails,
}) => {
  if (!studentPersonalDetails) return null;

  return (
    <div className="student-personal-details-wrapper">
      <div className="student-personal-details-top">
        <span className="text">Personal Details</span>
        <span className="icon">
          <FaUserCircle size={20} />
        </span>
      </div>
      <hr className="horizontal-line" />
      <div className="student-personal-details-bottom">
        <div className="wrapper-1">
          {/* TODO: it should map */}
          <p className="data-wrapper">
            <span className="text">Name</span>
            <span className="data">{studentPersonalDetails.name}</span>
          </p>
          <p className="data-wrapper">
            <span className="text">Email</span>
            <span className="data">{studentPersonalDetails.email}</span>
          </p>
          <p className="data-wrapper">
            <span className="text">Phone</span>
            <span className="data">{studentPersonalDetails.phone}</span>
          </p>
          <p className="data-wrapper">
            <span className="text">Address</span>
            <span className="data">
              {studentPersonalDetails.currentLocation}
            </span>
          </p>
          <p className="data-wrapper">
            <span className="text">Current Module</span>
            <span className="data">{studentPersonalDetails.latestCourse}</span>
          </p>
          <p className="data-wrapper">
            <span className="text">Batch age</span>
            <span className="data">{studentPersonalDetails.batchAge}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentPersonalDetails;
