const InfoBox = ({ title, data }: any) => {
  return (
    <div className="info-box-wrapper">
      <span className="title">{title}</span>
      <span className="data">{data}</span>
    </div>
  );
};

export default InfoBox;
