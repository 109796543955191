import { GridColDef } from "@mui/x-data-grid";
import { Button, Divider, Dropdown, Progress, Space, Switch } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useLocation } from "react-router";
import { CourseDetails } from "../components/followUpPage/CourseDetails";
import FollowUpFeed from "../components/followUpPage/FollowUpFeed";
import { PlacementDetails } from "../components/followUpPage/PlacementDetails";
import { TaskData } from "../components/followUpPage/TaskData";
import DataGridComponent from "../components/shared/DataGrid";
import { TabPanel } from "../components/shared/TabPanel";
import { FollowUpPageTabs } from "../config/followUpPageConfig";
import {
  fetchAdhocTasks,
  fetchBmTaskDetailsData,
  fetchFollowUpTaskInfo,
  fetchRegularFollowUpTasksInfo,
  fetchTodaysFollowUpTaskCount,
} from "../redux/followUp/followUp.thunk";
import { FollowUpTasksInfoInterface } from "../redux/followUp/followUp.type";
import { RootStateType, useAppDispatch, useAppSelector } from "../redux/store";

const FollowUpPage = () => {
  // const navigate = useNavigate();

  const [followUpColumn, setFollowUpColumn] = useState<GridColDef[]>([]);
  const [selectedFollowUpTask, setSelectedFollowUpTask] =
    useState<FollowUpTasksInfoInterface>();
  const [filteredRows, setFilteredRows] =
    useState<Array<FollowUpTasksInfoInterface>>();

  const dispatch = useAppDispatch();

  const location = useLocation();
  const {
    regularFollowUpTasksInfo,
    followUpTasksInfo,
    adhocTasks,
    todaysFollowUpTaskCount,
    taskDetails,
  } = useAppSelector((state: RootStateType) => state.followUp);

  const [selectedTab, setSelectedTab] = useState<string>(
    FollowUpPageTabs[0]?.value
  );

  const taskId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get("taskId");
    return taskId;
  }, [location]);

  const userId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get("userId");
  }, [location]);

  useEffect(() => {
    setFollowUpColumn([
      { field: "id", headerName: "ID" },
      {
        field: "userName",
        headerName: "Name",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "userEmail",
        headerName: "Email",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "userPhoneNumber",
        headerName: "Phone",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "latestCourse",
        headerName: "Current Unit",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "repeatCount",
        headerName: "Repeat Count",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      ...(taskId
        ? [
            {
              field: "taskStatus",
              headerName: "Current Task Status",
              flex: 1,
              minWidth: 150,
              editable: false,
              sortable: false,
            },
          ]
        : []),
      {
        field: "currentStatus",
        headerName: "Current Status",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "callAttemptStatus",
        headerName: "Call Attempt Status",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "daysSinceLastActivity",
        headerName: "Last Activity",
        flex: 1,
        minWidth: 100,
        editable: false,
        sortable: false,
      },
      {
        field: "lastFollowUpDate",
        headerName: "Last Follow Up Date",
        valueGetter: (params) => {
          return params.value
            ? moment(params.value).format("DD-MMM-YYYY")
            : "Not Scheduled";
        },
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "nextFollowUpDate",
        headerName: "Next Follow Up Date",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        valueGetter: (params) => {
          return params.value
            ? moment(params.value).format("DD-MMM-YYYY")
            : "Not Scheduled";
        },
      },
      {
        field: "inProgressTasks",
        headerName: "In Progress Tasks Left",
        flex: 1,
        minWidth: 175,
        editable: false,
        sortable: false,
      },
      {
        field: "toDoTasks",
        headerName: "Total Tasks Left",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
    ]);
  }, [taskId]);

  const getFollowUpTasks = () => {
    if (taskId) {
      dispatch(fetchFollowUpTaskInfo({ taskId }));
      dispatch(fetchBmTaskDetailsData({ taskId }));
    } else {
      dispatch(fetchTodaysFollowUpTaskCount());
      dispatch(fetchRegularFollowUpTasksInfo());
    }
  };

  useEffect(() => {
    getFollowUpTasks();
    dispatch(fetchAdhocTasks());
  }, []);

  const getTasksStatus = (percentage: number) => {
    if (percentage <= 0) {
      return "#888888";
    } else if (percentage > 0 && percentage <= 40) {
      return "#ff6b4d";
    } else if (percentage > 40 && percentage <= 70) {
      return "#ffa500";
    } else if (percentage > 70 && percentage < 100) {
      return "#1677FF";
    } else if (percentage === 100) {
      return "#00cc66";
    } else {
      return "#888888";
    }
  };

  const handelRowClick = (e: { row: FollowUpTasksInfoInterface }) => {
    setSelectedFollowUpTask(e.row);
  };

  const getTabPanelComponent = (tabValue: string) => {
    if (!selectedFollowUpTask) return null;

    switch (tabValue) {
      case "courseDetails":
        return <CourseDetails userId={selectedFollowUpTask.userId} />;
      case "placementDetails":
        return <PlacementDetails userId={selectedFollowUpTask.userId} />;
      default:
        return null;
    }
  };

  const onSwitchToggle = (checked: boolean) => {
    if (!checked) {
      setFilteredRows(regularFollowUpTasksInfo);
      return;
    }
    const rows: Array<FollowUpTasksInfoInterface> = [];
    // cycleEndDate will 4th day of every month, if 4th passed then it will be next month 4th else current month 4th
    const cycleEndDate =
      moment().date() > 4
        ? moment().add(1, "months").date(4)
        : moment().date(4);
    // cycleStartDate will this month 5th, if 5th not paased then it will be previous month 5th
    const cycleStartDate =
      moment().date() < 5
        ? moment().subtract(1, "months").date(5)
        : moment().date(5);
    regularFollowUpTasksInfo.forEach((task) => {
      if (
        !task.lastFollowUpDate ||
        !moment(task.lastFollowUpDate).isBetween(cycleStartDate, cycleEndDate)
      ) {
        rows.push(task);
      }
    });
    setFilteredRows(rows);
  };

  return (
    <div className="page-container follow-up-page-wrapper">
      <p className="title-wrapper">
        <span className="text"> Follow up </span>
      </p>

      {taskId && followUpTasksInfo ? (
        <div className="follow-up-data">
          <div className="left">
            {taskDetails ? (
              <TaskData
                taskTitle={taskDetails.title}
                deadline={taskDetails.deadline}
                priority={taskDetails.priority}
                showIcon={false}
              />
            ) : null}
          </div>
          <div className="right">
            <p className="task-progress-title">Task Progress</p>
            <div className="task-progress">
              <span className="text">Partially Done :</span>
              <Progress
                type="circle"
                showInfo={false}
                percent={
                  Number(
                    (followUpTasksInfo.inProgressStudentCount * 100) /
                      followUpTasksInfo.totalStudentCount
                  ) ?? 0
                }
                strokeColor={getTasksStatus(
                  (followUpTasksInfo.inProgressStudentCount * 100) /
                    followUpTasksInfo.totalStudentCount
                )}
                size={35}
                strokeWidth={15}
              />
              <span
                className="task-text"
                style={{
                  color: getTasksStatus(
                    (followUpTasksInfo.inProgressStudentCount * 100) /
                      followUpTasksInfo.totalStudentCount
                  ),
                }}
              >
                {followUpTasksInfo.inProgressStudentCount} /
                {followUpTasksInfo.totalStudentCount} students
              </span>
            </div>
            <div className="task-progress">
              <span className="text">To be picked up :</span>
              <Progress
                type="circle"
                showInfo={false}
                percent={
                  Number(
                    (followUpTasksInfo.toDoStudentCount * 100) /
                      followUpTasksInfo.totalStudentCount
                  ) ?? 0
                }
                strokeColor={getTasksStatus(
                  (followUpTasksInfo.toDoStudentCount * 100) /
                    followUpTasksInfo.totalStudentCount
                )}
                size={35}
                strokeWidth={15}
              />
              <span
                className="task-text"
                style={{
                  color: getTasksStatus(
                    (followUpTasksInfo.toDoStudentCount * 100) /
                      followUpTasksInfo.totalStudentCount
                  ),
                }}
              >
                {followUpTasksInfo.toDoStudentCount} /
                {followUpTasksInfo.totalStudentCount} students
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="regular-follow-up-data">
          <div className="regular-follow-up-data-wrapper">
            <div className="top">Regular Follow-Ups Taken Today</div>
            <div className="bottom">{todaysFollowUpTaskCount}</div>
          </div>
        </div>
      )}

      <h4>Filter adhoc tasks</h4>
      <div
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Dropdown
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as HTMLElement;
          }}
          menu={{
            items: adhocTasks.map((task) => {
              return {
                key: task.id,
                label: task.name,
              };
            }),
            onClick: (e) => {
              window.location.href = `/follow-up?taskId=${e.key}`;
            },
          }}
        >
          <Button>
            <Space>
              Adhoc Tasks
              <FiChevronDown />
            </Space>
          </Button>
        </Dropdown>
        {!taskId ? (
          <div
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <h3>Remaining Students</h3>
            <Switch onChange={onSwitchToggle} />
          </div>
        ) : null}
      </div>
      <div className="tasks-grid-wrapper">
        <DataGridComponent
          rows={
            taskId
              ? followUpTasksInfo?.studentData ?? []
              : filteredRows ?? regularFollowUpTasksInfo
          }
          columns={followUpColumn}
          onRowClick={handelRowClick}
        />
      </div>

      <Divider />

      {selectedFollowUpTask ? (
        <div className="follow-up-feed-wrapper">
          <FollowUpFeed
            selectedFollowUpTask={selectedFollowUpTask}
            onSomethingUpdated={getFollowUpTasks}
          />
          <TabPanel
            tabs={FollowUpPageTabs}
            getTabPanelComponent={getTabPanelComponent}
            name="follow-up-page"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FollowUpPage;
