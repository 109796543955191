import { useEffect, useState } from "react";

const useIsMobile = (): boolean => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = (): void => {
			const isMobileView = window.innerWidth <= 768; // Change the value as per your breakpoint
			setIsMobile(isMobileView);
		};

		handleResize(); // Check initial viewport size

		window.addEventListener("resize", handleResize); // Listen for viewport changes

		return () => {
			window.removeEventListener("resize", handleResize); // Clean up event listener on component unmount
		};
	}, []);
	return isMobile;
};

export default useIsMobile;
