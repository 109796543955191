import { Box } from "@mui/material";
import { useEffect, useState, type FC } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import Layout from "./components/layout";

import NavBar from "./components/navigation/Navbar";
import useAuthentication from "./hooks/useAuthentication";
import useIsMobile from "./hooks/useIsMobile";
import { fetchBatchManagerInfo } from "./redux/home/home.thunk";
import { RootStateType, useAppDispatch, useAppSelector } from "./redux/store";
import { fetchUserReport } from "./redux/user/user.thunk";

const AuthenticationCheck: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchBatchManagerInfo());
  }, []);
  const isMobile = useIsMobile();

  const allowedRoles = [
    "instructor",
    "maincourse-user",
    "data-maincourse-user",

    "batch-manager",
  ];

  const authenticated = useAuthentication({
    allowedRoles,
    callBack: async () => {
      await dispatch(fetchUserReport());
    },
  });

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsMobileNavOpen(false);
    }
  }, []);

  const bmAllDetails = useAppSelector(
    (state: RootStateType) => state.home.batchManagerDetails
  );

  return (
    <Box className={"layout-wrapper"}>
      <ToastContainer />

      <NavBar name={bmAllDetails?.firstName} />

      <Layout isNavOpen={isMobileNavOpen} setNavState={setIsMobileNavOpen}>
        {authenticated ? <App /> : <Box className={"authLoader"}></Box>}
        {/* <App /> */}
      </Layout>
    </Box>
  );
};

export default AuthenticationCheck;
