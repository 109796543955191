import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import { FollowUpReducer } from "./followUp/followUp.slice";

import { HomeReducer } from "./home/home.slice";
import { UserReducer } from "./user/user.slice";

const storeSync = configureStore({
  reducer: {
    user: UserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createStateSyncMiddleware()),
});

const store = configureStore({
  reducer: {
    user: UserReducer,
    home: HomeReducer,
    followUp: FollowUpReducer,
  },
});

initStateWithPrevTab(storeSync);
export default store;

// For better usage with typescript
export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;
export const useAppDispatch: () => AppDispatchType = useDispatch;
export const useAppSelector = <T>(selector: (state: RootStateType) => T): T =>
  useSelector(selector);
