import { Modal, ModalClose, Sheet } from "@mui/joy";
import { useEffect, useState, type FC } from "react";
import { Services } from "../../services";

type UpcomingEventsPropsType = {
  isOpen: boolean;
  onClose: () => void;
  studentId: string;
};

type UpcomingEventsResponseType = {
  upcomingEvents: {
    upcomingClasses?: Array<{
      title: string;
      start_time: string;
    }>;
    upcomingContests?: Array<{
      name: string;
      startTime: string;
    }>;
    upcomingSessions?: Array<{
      sessionType: string;
      sessionDate: string;
    }>;
    upcomingPlacementApplications?: Array<{
      driveName: string;
      updatedAt: string;
    }>;
  };
};

type UpcomingEventsStateType = Array<{
  title: string;
  date: string;
  type: string;
}>;

export const UpcomingEvents: FC<UpcomingEventsPropsType> = ({
  studentId,
  isOpen,
  onClose,
}) => {
  const [upcomingEvents, setUpcomingEvents] = useState<UpcomingEventsStateType>(
    []
  );

  useEffect(() => {
    Services.followUpService
      .getUpcomingEvents(studentId)
      .then((res: UpcomingEventsResponseType) => {
        const events: UpcomingEventsStateType = [];
        res.upcomingEvents.upcomingClasses?.forEach((item: any) => {
          events.push({
            title: item.title,
            date: item.start_time,
            type: "UPCOMING CLASSES",
          });
        });
        res.upcomingEvents.upcomingContests?.forEach((item: any) => {
          events.push({
            title: item.name,
            date: item.startTime,
            type: "UPCOMING CONTESTS",
          });
        });
        res.upcomingEvents.upcomingSessions?.forEach((item: any) => {
          events.push({
            title: item.sessionType,
            date: item.sessionDate,
            type: "UPCOMING SESSIONS",
          });
        });
        res.upcomingEvents.upcomingPlacementApplications?.forEach(
          (item: any) => {
            events.push({
              title: item.driveName,
              date: item.updatedAt,
              type: "UPCOMING PLACEMENT APPLICATIONS",
            });
          }
        );

        setUpcomingEvents(events);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [studentId]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 950,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          onClick={onClose}
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        <div className="modal-content-wrapper">
          <h3 style={{ textAlign: "center", marginBottom: 12 }}>
            Upcoming Events
          </h3>
          {upcomingEvents.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {upcomingEvents.map((event) => {
                  return (
                    <tr>
                      <td>{event.title}</td>
                      <td>{event.type}</td>
                      <td>{event.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h3 style={{ textAlign: "center", marginBottom: 12 }}>
              No Upcoming Events
            </h3>
          )}
        </div>
      </Sheet>
    </Modal>
  );
};
