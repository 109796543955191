import { singleton } from "../utils/singleton";

import { ApiFailureErrorMessage, HttpService } from "./http.service";

type RegularFollowUpTasksInput = {
  userId: string;
  isUpdate?: boolean;
  callAttemptStatus?: string;
  isFollowUp?: boolean;
  currentStatus?: string;
  currentSubStatus?: string;
  modeOfConversation?: string;
  nextFollowUpDate?: string | null;
  notes?: string;
  duration?: number;
  recordingLink?: string | null;
};

@singleton
export class FollowUpService {
  private endpoint = process.env.REACT_APP_ROOT;
  private httpService = new HttpService();

  async getRegularFollowUpTasksInfo() {
    const url = `${this.endpoint}/batch-manager-crm/regular-tasks`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getFollowUpTaskInfo(taskId: string) {
    const url = `${this.endpoint}/batch-manager-crm/followup-task/${taskId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getProfileOtherInfo(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/other-details`;
    try {
      const { data } = await this.httpService.post<any>(url, {
        data: {
          userId,
        },
      });
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getPlacementDetails(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/placement-details`;
    try {
      const { data } = await this.httpService.post<any>(url, {
        data: {
          userId,
        },
      });
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getCourseDetailsForLatestModule(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/student-feed/course-details/latest-module/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getCourseDetailsForGymDetails(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/student-feed/course-details/student-gym-details/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }
  async getUserMocks(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/student-feed/course-details/user-mocks/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getStudentPersonalDetails(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/student-feed/student-details/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getBMTasksForStudent(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/student-feed/tasks/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getFollowUpTasksForStudent(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/followup-history/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getModuleJourneyForStudent(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/student-feed/module-journey/${userId}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async addRegularFollowUpTask(data: RegularFollowUpTasksInput) {
    const url = `${this.endpoint}/batch-manager-crm/add-regular-followup`;
    try {
      const { data: responseData } = await this.httpService.post<any>(url, {
        data,
      });
      return responseData;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getRegularFollowUpTask(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/get-regular-followup`;
    try {
      const { data: responseData } = await this.httpService.post<any>(url, {
        data: {
          userId,
        },
      });
      return responseData;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getUpcomingEvents(userId: string) {
    const url = `${this.endpoint}/batch-manager-crm/upcoming-events/${userId}`;
    try {
      const { data: responseData } = await this.httpService.get<any>(url);
      return responseData;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getAdhocTasks() {
    const url = `${this.endpoint}/batch-manager-crm/adhoc-tasks`;
    try {
      const { data: responseData } = await this.httpService.get<any>(url);
      return responseData;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async getTodaysFollowUpCount() {
    const url = `${this.endpoint}/batch-manager-crm/followups-taken-today`;
    try {
      const { data: responseData } = await this.httpService.get<any>(url);
      return responseData;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async submitSubtaskSubmit(data: any) {
    const url = `${this.endpoint}/batch-manager-crm/non-followup-task`;
    try {
      const { data: responseData } = await this.httpService.patch<any>(url, {
        data,
      });
      return responseData;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }
}
