// import { , FormGroup } from "@mui/material";
import { Badge, Divider, Space } from "antd";
import { Button } from "antd/es/radio";
import moment from "moment";
import { useMemo } from "react";
import { BiTask } from "react-icons/bi";
import { BsDash } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import { useNavigate } from "react-router";
import {
  BmTaskDetailsInterface,
  PriorityEnum,
} from "../../redux/home/home.type";
import { MarkDownView } from "../shared/MarkDownView";

const AdHocFollowupTask = ({
  batchManagerTasksDetailsData,
  taskId,
}: {
  batchManagerTasksDetailsData: BmTaskDetailsInterface | null;
  taskId: string;
}) => {
  const navigate = useNavigate();

  const handleBadgeColor = (status: PriorityEnum) => {
    switch (status) {
      case PriorityEnum.LOW:
        return "#1677FF";
      case PriorityEnum.MEDIUM:
        return "#00cc66";
      case PriorityEnum.HIGH:
        return "#ff3300";
      default:
        return "#888888";
    }
  };

  const description = useMemo(
    () =>
      batchManagerTasksDetailsData?.description.replace(
        /(?:\r\n|\r|\n)/g,
        "<br>"
      ) ?? "",
    [batchManagerTasksDetailsData]
  );

  if (!batchManagerTasksDetailsData) return null;

  return (
    <div className="AdHocFollowupTask-wrapper">
      <div className="task-details-wrapper">
        <Badge.Ribbon
          text={batchManagerTasksDetailsData.priority}
          color={handleBadgeColor(batchManagerTasksDetailsData.priority)}
        >
          <div className="text-info-wrapper">
            <div className="left">
              <p className="details">
                <span className="title">Type : </span>
                <span className="text">
                  {batchManagerTasksDetailsData.type}
                </span>
              </p>
            </div>
            <div className="right">
              <div className="dead-line-wrapper">
                <span className="icon">
                  <FiClock />
                </span>
                <span className="text">
                  Deadline :{" "}
                  {moment(batchManagerTasksDetailsData.deadline).format(
                    "DD-MMM-YYYY"
                  )}
                </span>
              </div>
            </div>
          </div>
        </Badge.Ribbon>

        <div className="main-content-wrapper">
          <div className="left-content">
            <ul className="list-wrapper">
              <li className="list-item">
                <div className="text">Task </div>
                <div className="description">
                  {batchManagerTasksDetailsData.title}
                </div>
              </li>
              <li className="list-item">
                <div className="text">Description </div>
                <MarkDownView statement={description} />
              </li>
              <li className="list-item">
                <div className="text">Status</div>
                <div className="description">
                  Done:
                  <b>
                    {` ${batchManagerTasksDetailsData.status?.done} /
                      ${batchManagerTasksDetailsData.status?.total}`}
                  </b>{" "}
                  students
                </div>
              </li>
            </ul>
            <div className="button-wrapper">
              <Button
                className="button"
                onClick={() => {
                  navigate(`/follow-up?taskId=${taskId}`);
                }}
              >
                Go to Follow-Up Page
              </Button>
            </div>
          </div>
          <div className="right-content">
            <div>
              <p className="text-icon-wrapper">
                <span className="icon">
                  <BiTask />
                </span>
                <span className="text">Sub Tasks</span>
              </p>
              <div className="radio-wrapper">
                <Space direction="vertical">
                  {batchManagerTasksDetailsData.subtasks.length > 0
                    ? batchManagerTasksDetailsData.subtasks?.map(
                        (item: any, index) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <BsDash />
                            <label>{item["description"] as string}</label>
                          </div>
                        )
                      )
                    : null}
                </Space>
              </div>
            </div>

            <div className="note">
              <Divider />
              <p>
                Note: Follow Up task progress can be marked only from Follow Up
                Page
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdHocFollowupTask;
