import { Button } from "antd";

import { FC, useEffect, useState } from "react";
import { FollowUpTasksInfoInterface } from "../../redux/followUp/followUp.type";
import { Services } from "../../services";
import ActivityData, {
  ModuleJourneyStateType,
  RegularFollowUpTaskStateType,
} from "./ActivityData";
import FollowUpTask, { FollowUpTaskDataState } from "./FollowUpTask";
import StudentPersonalDetails, {
  StudentPersonalDetailsState,
} from "./StudentPersonalDetails";

type FollowUpFeedProps = {
  selectedFollowUpTask: FollowUpTasksInfoInterface;
  onSomethingUpdated: () => void;
};

const FollowUpFeed: FC<FollowUpFeedProps> = ({
  selectedFollowUpTask,
  onSomethingUpdated: onSomethingUpdatedFromProps,
}) => {
  const onLoginAsUser = async () => {
    try {
      const res = await Services.userService.loginAsUser(
        selectedFollowUpTask.userEmail
      );
      const token = res.accioJobsToken;
      window.open(
        `https://autologins.acciojob.com/?access_token=${token}`,
        "_blank"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const [followUpTaskData, setFollowUpTaskData] =
    useState<FollowUpTaskDataState>([]);

  const [moduleHistory, setModuleJourney] = useState<ModuleJourneyStateType>();

  const [regularFollowUpDetails, setRegularFollowUpDetails] =
    useState<RegularFollowUpTaskStateType>();

  const [studentPersonalDetails, setStudentPersonalDetails] =
    useState<StudentPersonalDetailsState>();

  const onInit = async () => {
    try {
      const res = await Promise.all([
        Services.followUpService.getBMTasksForStudent(
          selectedFollowUpTask.userId
        ),
        Services.followUpService.getStudentPersonalDetails(
          selectedFollowUpTask.userId
        ),
        Services.followUpService.getModuleJourneyForStudent(
          selectedFollowUpTask.userId
        ),
        Services.followUpService.getRegularFollowUpTask(
          selectedFollowUpTask.userId
        ),
      ]);

      setFollowUpTaskData(res[0]);
      setStudentPersonalDetails(res[1].studentPersonalDetails);
      setModuleJourney(res[2]);
      setRegularFollowUpDetails(res[3]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    onInit();
  }, [selectedFollowUpTask]);

  const onSomethingUpdated = async () => {
    await onInit();
    onSomethingUpdatedFromProps();
  };

  return (
    <div className="follow-up-feed-container">
      <div className="top-wrapper">
        <p className="title">Follow up feed</p>
        <div className="follow-up-quick-action">
          <div className="left">Quick Actions : </div>
          <div className="right">
            <Button onClick={onLoginAsUser}>Login as User</Button>
            <Button
              onClick={() => {
                window.open(
                  "https://internal.acciojob.com/app/profile-locking/lock-profile-6400943c11a1c526256319f2",
                  "_blank"
                );
              }}
            >
              Profile Lock
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://internal.acciojob.com/app/manage-student-placements/update-placement-status-64a56eeb2dc55c5e46d7c634",
                  "_blank"
                );
              }}
            >
              Placement Update
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "	https://internal.acciojob.com/applications/63dbcba2bcc76d2f1c3181f9/pages/63dbcba3bcc76d2f1c31820a",
                  "_blank"
                );
              }}
            >
              Mock/Mentorship
            </Button>
          </div>
        </div>
      </div>
      <div className="bottom-wrapper">
        <div className="left-content">
          <StudentPersonalDetails
            studentPersonalDetails={studentPersonalDetails}
          />
          <FollowUpTask
            followUpTaskData={followUpTaskData}
            userId={selectedFollowUpTask.userId}
            onSomethingUpdated={onSomethingUpdated}
            regularFollowUpDetails={regularFollowUpDetails}
          />
        </div>
        <div className="right-content">
          <ActivityData
            regularFollowUpDetails={regularFollowUpDetails}
            userId={selectedFollowUpTask.userId}
            moduleHistory={moduleHistory}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowUpFeed;
