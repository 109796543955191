import { ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { MdClose, MdLogout } from "react-icons/md";
import { NavLink } from "react-router-dom";
import accioLogo from "../../assets/logo/acciojob.svg";
import accioLogoName from "../../assets/logo/logoWithName.svg";
import useIsMobile from "../../hooks/useIsMobile";
import { removeAuthCookie } from "../../utils/authCookie";

interface NavigationItem {
  to: string;
  label: string;
}

interface NavItemProps {
  to: string;
  label: string;
  onClick: () => void;
}

interface NavBarProps {
  name?: string;
}

const navigationItems: NavigationItem[] = [
  { to: "/", label: "Home" },
  { to: "/follow-up", label: "Follow Up Page" },
  { to: "/all-tasks", label: "All Tasks" },
];

const NavItem: React.FC<NavItemProps> = ({ to, label, onClick }) => (
  <li className="nav-item">
    <NavLink to={to} className="nav-links" onClick={onClick}>
      {label}
    </NavLink>
  </li>
);

const NavBar: React.FC<NavBarProps> = ({ name }) => {
  const [click, setClick] = useState<boolean>(false);

  const handleClick = (): void => setClick(!click);

  const isMobile = useIsMobile();

  const closeMenu = (): void => {
    if (click) {
      setClick(false);
    }
  };

  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);

  const handleClickAwayEvent = () => {
    setProfileMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <NavLink to="/" className="nav-logo" onClick={closeMenu}>
          {isMobile ? (
            <img src={accioLogo} alt="logo" />
          ) : (
            <img src={accioLogoName} alt="logo" />
          )}
        </NavLink>

        <ul className={`nav-menu ${click ? "active" : ""}`}>
          {navigationItems.map((item: NavigationItem, index: number) => (
            <NavItem
              key={index}
              to={item.to}
              label={item.label}
              onClick={closeMenu}
            />
          ))}
        </ul>

        <div className="nav-icon" onClick={handleClick}>
          {click ? <MdClose /> : <HiMenu />}
        </div>
        <ClickAwayListener onClickAway={handleClickAwayEvent}>
          <button
            className="profile-menu-icon"
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
          >
            {name?.charAt(0).toUpperCase() ?? "U"}
          </button>
        </ClickAwayListener>

        <div
          className={`profile-menu-wrapper ${profileMenuOpen ? "open" : ""}`}
        >
          <button className="log-out-btn" onClick={() => removeAuthCookie({})}>
            Log Out
            <MdLogout size={16} />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
