import { createAsyncThunk } from "@reduxjs/toolkit";

import { Services } from "../../services";

export const fetchBatchManagerInfo = createAsyncThunk(
  "batchManagerInfo/home",
  async () => {
    try {
      return Services.homeService.getBatchManagerInfo();
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchBmTasksData = createAsyncThunk(
  "batchManagersTasks/home",
  async ({ bmId }: { bmId: string }) => {
    try {
      return Services.homeService.getBmTasksData({ bmId });
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchBmAllTasksData = createAsyncThunk(
  "batchManagersAllTasks/home",
  async ({ bmId }: { bmId: string }) => {
    try {
      return Services.homeService.getBmAllTasksData({ bmId });
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchBmTaskDetailsData = createAsyncThunk(
  "batchManagersTasksDetailsData/home",
  async ({ taskId }: { taskId: string }) => {
    try {
      return Services.homeService.getBmTaskDetailsData({ taskId });
    } catch (error) {
      console.log(error);
    }
  }
);
