import { Progress } from "antd";
import moment from "moment";
import { useEffect, useState, type FC } from "react";
import { useNavigate } from "react-router";
import DataGridComponent from "../components/shared/DataGrid";
import AdHocFollowupTask from "../components/taskTypeComponent/AdHocFollowupTask";
import AdHocNonFollowupTask from "../components/taskTypeComponent/AdHocNonFollowupTask";
import {
  fetchBmTaskDetailsData,
  fetchBmTasksData,
} from "../redux/home/home.thunk";
import { BmTaskInterface, TaskTypeEnum } from "../redux/home/home.type";
import { RootStateType, useAppDispatch, useAppSelector } from "../redux/store";
// import { feedbacKData } from "../utils/data";
import { CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { getTaskPriority, getTasksStatus } from "../utils/utilFunctions";

const Home: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [rows, setRows] = useState<Array<BmTaskInterface>>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [selectedTask, setSelectedTask] = useState<BmTaskInterface | null>(
    null
  );

  const {
    batchManagerDetails,
    batchManagerTasksData,
    batchManagerTasksDetailsData,
    homePageLoading,
  } = useAppSelector((state: RootStateType) => state.home);

  useEffect(() => {
    setColumns([
      { field: "id", headerName: "ID" },
      {
        field: "title",
        headerName: "Title",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 200,
        editable: false,
        sortable: false,
        renderCell: (params: any) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "scroll",
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "priority",
        headerName: "Priority",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        renderCell: (params: any) => (
          <>
            <Progress
              steps={3}
              strokeWidth={15}
              showInfo={false}
              percent={getTaskPriority(params.value).percentage}
              strokeColor={getTaskPriority(params.value).color}
            />
            <span
              className="crm-ant-text-com"
              style={{ color: getTaskPriority(params.value).color }}
            >
              {params.value}
            </span>
          </>
        ),
      },
      {
        field: "taskStatus",
        headerName: "TaskStatus",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        renderCell: (params: any) => {
          const color = getTasksStatus(params.value).color;
          return (
            <>
              <Progress
                type="circle"
                showInfo={false}
                percent={getTasksStatus(params.value).percentage}
                status={getTasksStatus(params.value).status}
                size={35}
                strokeWidth={15}
                strokeColor={color}
              />
              <span className="crm-ant-text-com" style={{ color }}>
                {params.value}
              </span>
            </>
          );
        },
      },
      {
        field: "deadline",
        headerName: "Deadline",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        valueGetter: (params) => {
          return params.value
            ? moment(params.value).format("DD-MMM-YYYY")
            : "N/A";
        },
      },
      {
        field: "owner",
        headerName: "Owner",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "assigner",
        headerName: "Assigner",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
    ]);
  }, []);

  useEffect(() => {
    try {
      if (batchManagerTasksData && batchManagerTasksData.length > 0)
        setRows(batchManagerTasksData);
    } catch (error) {
      console.error(error);
    }
  }, [batchManagerTasksData]);

  useEffect(() => {
    if (batchManagerDetails && batchManagerDetails.id) {
      dispatch(fetchBmTasksData({ bmId: batchManagerDetails.id }));
    }
  }, [batchManagerDetails]);

  const handelRowClick = (e: { row: BmTaskInterface }) => {
    if (e.row.type === TaskTypeEnum.REGULAR_FOLLOWUP_TASK) {
      navigate("/follow-up");
      return;
    } else {
      if (e.row.taskId) {
        setSelectedTask(null);
        dispatch(fetchBmTaskDetailsData({ taskId: e.row.taskId }));
      }
    }

    setSelectedTask(e.row);
  };

  return (
    <div className="page-container home-wrapper">
      {batchManagerDetails ? (
        <p className="title-wrapper">
          <span className="text"> Welcome back</span>
          <span className="name">
            {batchManagerDetails?.firstName +
              " " +
              batchManagerDetails?.lastName ?? " "}{" "}
            !
          </span>
        </p>
      ) : null}
      <p className="sub-text">Here are your tasks for the day</p>

      <div className="tasks-grid-wrapper">
        <DataGridComponent
          rows={rows}
          columns={columns}
          onRowClick={handelRowClick}
        />
      </div>
      {selectedTask ? (
        <div className="task-view">
          {homePageLoading ? <CircularProgress /> : null}
          {selectedTask?.type === TaskTypeEnum.AD_HOC_FOLLOWUP_TASK ? (
            <AdHocFollowupTask
              batchManagerTasksDetailsData={batchManagerTasksDetailsData}
              taskId={selectedTask.taskId}
            />
          ) : null}
          {selectedTask?.type === TaskTypeEnum.AD_HOC_NON_FOLLOWUP_TASK ? (
            <AdHocNonFollowupTask
              batchManagerTasksDetailsData={batchManagerTasksDetailsData}
              taskId={selectedTask?.taskId}
            />
          ) : null}
          {selectedTask?.type === TaskTypeEnum.BM_AD_HOC_TASK ? (
            <AdHocNonFollowupTask
              batchManagerTasksDetailsData={batchManagerTasksDetailsData}
              taskId={selectedTask?.taskId}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Home;
