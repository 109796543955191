import { Tooltip } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

type Day = {
  day: number;
  firstsolveddate: string;
  intensity: number;
  score: number;
};

type MonthData = {
  month: string;
  data: Day[][];
};

export type HeatmapPropsType = {
  data: Day[];
  legend?: boolean;
};

const Heatmap = ({ data, legend = true }: HeatmapPropsType) => {
  const [monthMapData, setMonthMapData] = useState<MonthData[]>([]);

  const arrayFiller = (week: Day[]) => {
    const temp = new Array(7).fill(-1);
    week.forEach((el) => {
      const day = el.day;
      temp[day] = el;
    });
    return temp;
  };

  const generateWeek = (month: Day[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    const finalWeeks: Day[][] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    let week: Day[] = [];
    for (const day of month) {
      if (day.day === 0) {
        if (!arrayFiller(week).every((ele) => ele === -1)) {
          finalWeeks.push(arrayFiller(week));
        }
        week = [day];
      } else {
        week.push(day);
      }
    }
    finalWeeks.push(arrayFiller(week));
    return finalWeeks;
  };

  useEffect(() => {
    if ((data?.length || 0) > 0) {
      let finalResult = [...data];
      finalResult = finalResult.reverse();
      const tempArray: Day[][] = [];
      let temp: Day[] = [];
      let month = 0;
      for (let i = 0; i < finalResult.length; i++) {
        const dayData = { ...finalResult[i] };
        dayData.day = moment(dayData.firstsolveddate).day();
        if (i === 0) {
          temp.push(dayData);
          month = moment(dayData.firstsolveddate).month();
        } else {
          if (moment(dayData.firstsolveddate).month() === month) {
            temp.push(dayData);
          } else {
            tempArray.push(temp);
            temp = [];
            temp.push(dayData);
            month = moment(dayData.firstsolveddate).month();
          }
        }
      }
      tempArray.push(temp);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      const modifiedMonth: MonthData[] = [];
      for (const month of tempArray) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (month !== -1) {
          const a = {
            month: moment(month[0].firstsolveddate).format("MMMM"),
            data: generateWeek(month),
          };
          modifiedMonth.push(a);
        }
      }
      setMonthMapData(modifiedMonth);
    }
  }, [data]);

  return (
    <>
      {/* heatmap display */}
      <div className="heatmap-container">
        <div
          className="gym-display-heatmap"
          style={{
            justifyContent: !legend ? "space-between" : "",
          }}
        >
          {monthMapData &&
            monthMapData.length > 1 &&
            monthMapData.map((month, i) => (
              <div key={i} className="heatmap-month-outer">
                <div className="heatmap-month-dislpay">{month.month}</div>
                <div className="heatmap-month">
                  {month.data.map((week, index) => (
                    <div key={index} className="heatmap-week">
                      {week.map((day: any, ind: number) => (
                        <div
                          key={ind}
                          className="heatmap-day"
                          id={`day-${ind}`}
                        >
                          {day === -1 ? (
                            <div className="heat-map-box heatmap-empty-day-no-show"></div>
                          ) : (
                            <Tooltip
                              title={
                                "Score " +
                                day.score +
                                " on " +
                                moment(day.firstsolveddate).format("LL")
                              }
                              placement="top"
                              arrow
                            >
                              <div
                                className="heat-map-box heatmap-work-day-to-show"
                                data-level={`${day.intensity}`}
                              ></div>
                            </Tooltip>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>

        {/* heatmap days */}
        <div className="heatmap-month-outer heat-map-only-days">
          <span
            className={`heatmap-day ${
              legend ? "heatmap-no-show-day" : "lightgrey-day"
            }`}
          >
            Sun
          </span>
          <span className="heatmap-day">Mon</span>
          <span
            className={`heatmap-day ${
              legend ? "heatmap-no-show-day" : "lightgrey-day"
            }`}
          >
            Tue
          </span>
          <span className="heatmap-day">Wed</span>
          <span
            className={`heatmap-day ${
              legend ? "heatmap-no-show-day" : "lightgrey-day"
            }`}
          >
            Thu
          </span>
          <span className="heatmap-day">Fri</span>
          <span
            className={`heatmap-day ${
              legend ? "heatmap-no-show-day" : "lightgrey-day"
            }`}
          >
            Sat
          </span>
        </div>
      </div>

      {/* legends display */}
      {legend ? (
        <div className="legends-display-container-outer">
          <div className="legends-display-text">less</div>
          <div className="legends-display" data-level="0"></div>
          <div className="legends-display" data-level="1"></div>
          <div className="legends-display" data-level="2"></div>
          <div className="legends-display" data-level="3"></div>
          <div className="legends-display" data-level="4"></div>
          <div className="legends-display" data-level="5"></div>
          <div className="legends-display-text">more</div>
        </div>
      ) : null}
    </>
  );
};

export default Heatmap;
