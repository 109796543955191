import { ModalClose, Sheet } from "@mui/joy";
import { Modal } from "@mui/material";
import { useEffect, useState, type FC } from "react";
import { Services } from "../../services";
import { MarkDownView } from "../shared/MarkDownView";

type FollowUpHistoryPropsType = {
  isOpen: boolean;
  onClose: () => void;
  studentId: string;
};

type FollowUpHistoryStateType = {
  currentStatus: string;
  modeOfConversation: string;
  notes: string;
  duration: number;
  createdAt: string;
};

export const FollowUpHistory: FC<FollowUpHistoryPropsType> = ({
  isOpen,
  onClose,
  studentId,
}) => {
  const [followUpHistory, setFollowUpHistory] = useState<
    FollowUpHistoryStateType[]
  >([]);

  useEffect(() => {
    if (isOpen) {
      Services.followUpService
        .getFollowUpTasksForStudent(studentId)
        .then((res) => {
          setFollowUpHistory(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isOpen]);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 950,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          onClick={onClose}
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        <div className="modal-content-wrapper">
          <h3 style={{ textAlign: "center", marginBottom: 12 }}>
            Follow Up History
          </h3>
          {followUpHistory.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Current Status</th>
                  <th>Mode of Conversation</th>
                  <th>Notes</th>
                  <th>Duration</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {followUpHistory.map((item) => (
                  <tr>
                    <td>{item.currentStatus}</td>
                    <td>{item.modeOfConversation}</td>
                    <td>
                      <MarkDownView statement={item.notes} />
                    </td>
                    <td>{item.duration}</td>
                    <td>{item.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ textAlign: "center" }}>No follow up history found</p>
          )}
        </div>
      </Sheet>
    </Modal>
  );
};
