import { Badge, Checkbox, Space } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TextArea from "antd/es/input/TextArea";
import { Button } from "antd/es/radio";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { BiTask } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import { PiNotebook } from "react-icons/pi";
import { fetchBmTasksData } from "../../redux/home/home.thunk";
import {
  BmTaskDetailsInterface,
  PriorityEnum,
} from "../../redux/home/home.type";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Services } from "../../services";
import { notifyError, notifySuccess } from "../notification";
import { MarkDownView } from "../shared/MarkDownView";

type AdHocNonFollowupTaskPropsType = {
  batchManagerTasksDetailsData: BmTaskDetailsInterface | null;
  taskId: string;
};

const AdHocNonFollowupTask: FC<AdHocNonFollowupTaskPropsType> = ({
  batchManagerTasksDetailsData,
  taskId,
}) => {
  const [value, setValue] = useState<
    Array<{
      id: string;
      description: string;
    }>
  >([]);
  const [remarks, setRemarks] = useState<string>("");
  const dispatch = useAppDispatch();
  const { batchManagerDetails } = useAppSelector((state) => state.home);

  const handleBadgeColor = (status: PriorityEnum) => {
    switch (status) {
      case PriorityEnum.LOW:
        return "#1677FF";
      case PriorityEnum.MEDIUM:
        return "#00cc66";
      case PriorityEnum.HIGH:
        return "#ff3300";
      default:
        return "#888888";
    }
  };

  useEffect(() => {
    if (batchManagerTasksDetailsData?.subtasks)
      setValue(
        batchManagerTasksDetailsData.subtasks
          .filter((item) => item.status === "COMPLETED")
          .map((item) => ({
            id: item.subtask.id,
            description: item.subtask.description,
          }))
      );
    if (batchManagerTasksDetailsData?.remarks) {
      setRemarks(batchManagerTasksDetailsData?.remarks);
    }
  }, [batchManagerTasksDetailsData]);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setValue([
        ...value,
        {
          id: e.target.value,
          description: e.target.title as string,
        },
      ]);
    } else {
      setValue(value.filter((item) => item.id !== e.target.value));
    }
  };

  const onSubmit = async () => {
    try {
      await Services.followUpService.submitSubtaskSubmit({
        taskId,
        remarks,
        subtasksDone: value,
        subtasksTodo: batchManagerTasksDetailsData?.subtasks
          .filter(
            (subtask: any) =>
              !value.find(
                (selectedSubtask) => selectedSubtask.id === subtask.subtask.id
              )
          )
          .map((subtask: any) => subtask.subtask),
      });
      notifySuccess("Task status updated successfully");
      dispatch(fetchBmTasksData({ bmId: batchManagerDetails!.id }));
    } catch (error) {
      notifyError("Error while updating task status");
    }
  };

  const description = useMemo(
    () =>
      batchManagerTasksDetailsData?.description.replace(
        /(?:\r\n|\r|\n)/g,
        "<br>"
      ) ?? "",
    [batchManagerTasksDetailsData]
  );

  if (!batchManagerTasksDetailsData) return null;

  return (
    <div className="AdHocNonFollowupTask-wrapper">
      <div className="task-details-wrapper">
        <Badge.Ribbon
          text={batchManagerTasksDetailsData.priority}
          color={handleBadgeColor(batchManagerTasksDetailsData.priority)}
        >
          <div className="text-info-wrapper">
            <div className="left">
              <p className="details">
                <span className="title">Task Type : </span>
                <span className="text">
                  {batchManagerTasksDetailsData.type}
                </span>
              </p>
            </div>
            <div className="right">
              <div className="dead-line-wrapper">
                <span className="icon">
                  <FiClock />
                </span>
                <span className="text">
                  Deadline :{" "}
                  {moment(batchManagerTasksDetailsData.deadline).format(
                    "DD-MMM-YYYY"
                  )}
                </span>
              </div>
            </div>
          </div>
        </Badge.Ribbon>

        <div className="main-content-wrapper">
          <div className="left">
            <ul className="list-wrapper">
              <li className="list-item">
                <div className="text">Task </div>
                <div className="description">
                  {batchManagerTasksDetailsData.title}
                </div>
              </li>
              <li className="list-item">
                <div className="text">Description </div>
                <MarkDownView statement={description} />
              </li>
              <li className="list-item">
                <div className="text">Additional Data</div>
                <div className="description">
                  <a
                    href={batchManagerTasksDetailsData.additionalInfo}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {batchManagerTasksDetailsData.additionalInfo}
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="right">
            <p className="text-icon-wrapper">
              <span className="icon">
                <BiTask />
              </span>
              <span className="text">Sub Tasks</span>
            </p>
            <div className="radio-wrapper">
              <Checkbox.Group value={value.map((item) => item.id)}>
                <Space direction="vertical">
                  {batchManagerTasksDetailsData?.subtasks.length > 0
                    ? batchManagerTasksDetailsData?.subtasks?.map((item) => {
                        console.log({ item });
                        return (
                          <Checkbox
                            value={item.subtask.id}
                            title={item.subtask.description}
                            onChange={handleCheckboxChange}
                          >
                            {item.subtask.description}
                          </Checkbox>
                        );
                      })
                    : null}
                </Space>
              </Checkbox.Group>
            </div>
            <p className="text-icon-wrapper">
              <span className="icon">
                <PiNotebook />
              </span>
              <span className="text">Remarks</span>
            </p>
            <div className="text-area-wrapper">
              <TextArea
                className="text-area"
                rows={4}
                value={remarks}
                placeholder="Enter your remarks..."
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </div>
            <div className="button-wrapper">
              <Button className="button" onClick={onSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdHocNonFollowupTask;
