import React, { useState } from "react";

interface FeedBackDataTreeViewProps {
  data: Record<string, any>;
  expanded?: boolean;
}

const FeedBackDataTreeView: React.FC<FeedBackDataTreeViewProps> = ({
  data,
  expanded: defaultExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  // Function to format a date in the desired format
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
  };

  // Function to format a number to two decimal places
  const formatNumber = (number: number) => {
    return number.toFixed(2); // Limit to two decimal places
  };

  // Function to render each data item
  const renderDataItem = (key: string, value: any) => {
    if (Array.isArray(value)) {
      // Render an array
      return (
        <div key={key} className="accordion-item">
          <strong>{key}:</strong>
          <ul>
            {value.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      // Render an object (nested accordion)
      return (
        <div key={key} className="accordion-item">
          <strong>{key}:</strong>
          <FeedBackDataTreeView data={value} />
        </div>
      );
    } else if (key === "createdAt") {
      // Format date for the "createdAt" key
      return (
        <div key={key} className="accordion-item">
          <strong>{key}:</strong> {formatDate(value)}
        </div>
      );
    } else if (typeof value === "number") {
      // Format numbers to two decimal places
      return (
        <div key={key} className="accordion-item">
          <strong>{key}:</strong> {formatNumber(value)}
        </div>
      );
    } else {
      // Render other data types
      return (
        <div key={key} className="accordion-item">
          <strong>{key}:</strong>{" "}
          {value !== undefined && value !== null ? value.toString() : "N/A"}
        </div>
      );
    }
  };

  return (
    <div
      className={`feedback-tree-view-accordion ${expanded ? "expanded" : ""}`}
    >
      <div className="accordion-header" onClick={toggleAccordion}>
        <span className="arrow">{expanded ? "▼" : "▶"}</span>
        {expanded ? "Collapse" : "Expand"}
      </div>
      {expanded && (
        <div className="accordion-content">
          {Object.keys(data).map((key) => renderDataItem(key, data[key]))}
        </div>
      )}
    </div>
  );
};

export default FeedBackDataTreeView;
