import { GridColDef } from "@mui/x-data-grid";
import { Button, Progress } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import DataGridComponent from "../components/shared/DataGrid";
import { fetchBmAllTasksData } from "../redux/home/home.thunk";
import { BmTaskInterface } from "../redux/home/home.type";
import { RootStateType, useAppDispatch, useAppSelector } from "../redux/store";
import { getTaskPriority, getTasksStatus } from "../utils/utilFunctions";

const ViewAllTask = () => {
  const { batchManagerDetails, batchManagerAllTasksData } = useAppSelector(
    (state: RootStateType) => state.home
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (batchManagerDetails && batchManagerDetails.id) {
      dispatch(fetchBmAllTasksData({ bmId: batchManagerDetails.id }));
    }
  }, [batchManagerDetails]);

  const [rows, setRows] = useState<Array<BmTaskInterface>>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    setColumns([
      { field: "id", headerName: "ID" },
      {
        field: "title",
        headerName: "Title",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 200,
        editable: false,
        sortable: false,
        renderCell: (params: any) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "scroll",
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "priority",
        headerName: "Priority",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        renderCell: (params: any) => (
          <>
            <Progress
              steps={3}
              strokeWidth={15}
              showInfo={false}
              percent={getTaskPriority(params.value).percentage}
              strokeColor={getTaskPriority(params.value).color}
            />
            <span
              className="crm-ant-text-com"
              style={{ color: getTaskPriority(params.value).color }}
            >
              {params.value}
            </span>
          </>
        ),
      },
      {
        field: "taskStatus",
        headerName: "TaskStatus",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        renderCell: (params: any) => {
          const color = getTasksStatus(params.value).color;
          return (
            <>
              <Progress
                type="circle"
                showInfo={false}
                percent={getTasksStatus(params.value).percentage}
                status={getTasksStatus(params.value).status}
                size={35}
                strokeWidth={15}
                strokeColor={color}
              />
              <span className="crm-ant-text-com" style={{ color }}>
                {params.value}
              </span>
            </>
          );
        },
      },
      {
        field: "deadline",
        headerName: "Deadline",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        valueGetter: (params) => {
          return params.value
            ? moment(params.value).format("DD-MMM-YYYY")
            : "N/A";
        },
      },
      {
        field: "owner",
        headerName: "Owner",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "assigner",
        headerName: "Assigner",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
    ]);
  }, []);

  useEffect(() => {
    try {
      if (batchManagerAllTasksData && batchManagerAllTasksData.length > 0)
        setRows(batchManagerAllTasksData);
    } catch (error) {
      console.error(error);
    }
  }, [columns, batchManagerAllTasksData]);

  const handelRowClick = (e: any) => {};
  return (
    <div className="view-all-task-wrapper">
      <div className="header">
        <p className="text">Your All Task</p>
        <div>
          <Button
            type="primary"
            onClick={() => {
              window.open(
                "https://internal.acciojob.com/app/task-management/add-tasks-655b032f49b82d6da88d0755",
                "_blank"
              );
            }}
          >
            Add Self Task
          </Button>
        </div>
      </div>
      <div className="tasks-grid-wrapper">
        <DataGridComponent
          rows={rows}
          columns={columns}
          onRowClick={(e: any) => handelRowClick(e)}
        />
      </div>
    </div>
  );
};

export default ViewAllTask;
