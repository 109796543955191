/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any -- any used to make everything generic */
export function singleton<
	T extends new (...args: Array<any>) => any
>(ClassTarget: T): T {
	let instance: any;

	// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- this is a singleton
	return function (...args: Array<any>) {
		if (!instance) {
			instance = new ClassTarget(...args);
		}
		return instance;
	} as unknown as T;
}
/* eslint-enable @typescript-eslint/no-explicit-any -- any used to make everything generic */
