export const getTasksStatus = (
  status: "TODO" | "INPROGRESS" | "COMPLETED" | "REJECTED"
): {
  status: "normal" | "success" | "exception";
  percentage: number;
  color: string;
} => {
  switch (status) {
    case "TODO":
      return {
        status: "normal",
        percentage: 0,
        color: "#888888",
      };
    case "INPROGRESS":
      return {
        status: "normal",
        percentage: 45,
        color: "#1677FF",
      };
    case "COMPLETED":
      return {
        status: "success",
        percentage: 100,
        color: "#00cc66",
      };
    case "REJECTED":
      return {
        status: "exception",
        percentage: 100,
        color: "#ff3300",
      };
    default:
      return {
        status: "normal",
        percentage: 0,
        color: "#888888",
      };
  }
};

export const getTaskPriority = (priority: string) => {
  switch (priority) {
    case "HIGH":
      return {
        color: "#BC1B06",
        percentage: 100,
      };

    case "MEDIUM":
      return {
        color: "#F79009",
        percentage: 50,
      };
    case "LOW":
      return {
        color: "#039855",
        percentage: 25,
      };
    default:
      return {
        color: "#888888",
        percentage: 0,
      };
  }
};
