export interface BmTaskInterface {
  title: string;
  type: TaskTypeEnum;
  priority: PriorityEnum;
  description: string;
  deadline: string;
  subtasks: Array<string>;
  taskStatus: TaskStatus;
  taskId: string;
  owner: string;
  assigner: string;
  status: {
    done: number;
    total: number;
  };
}

export enum TaskStatus {
  TODO = "TODO",
  INPROGRESS = "INPROGRESS",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
}

export enum PriorityEnum {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum TaskTypeEnum {
  AD_HOC_NON_FOLLOWUP_TASK = "Ad Hoc Non-followup task",
  AD_HOC_FOLLOWUP_TASK = "Ad Hoc Followup task",
  BM_AD_HOC_TASK = "BM Ad Hoc Task",
  REGULAR_FOLLOWUP_TASK = "Regular Followup task",
}

export interface BmTaskDetailsInterface {
  title: string;
  type: TaskTypeEnum;
  priority: PriorityEnum;
  description: string;
  deadline: string;
  additionalInfo: string;
  remarks: string;
  subtasks: Array<{
    status: string;
    subtask: {
      id: string;
      description: string;
    };
  }>;
  status: {
    done: number;
    total: number;
  };
}

export interface HomeInterface {
  batchManagerDetails: {
    id: string;
    firebaseId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    countrycode: number;
    isPhoneVerified: boolean;
    isWhatsAppVerify: boolean;
    isEmailVerified: boolean;
    isEmailSet: boolean;
    lastlogin: string;
    imageUrl: string | null;
    referredBy: string | null;
    joinedLiveClassDates: string[];
    joinedDemoSessionDates: string[];
    roles: Array<string>;
    utm_source: string | null;
    utm_campaign: string | null;
    utm_content: string | null;
    utm_medium: string | null;
    callbackRequest: string | null;
    callbackRequestTime: string | null;
    firstLoginAfterPrecourseStart: string | null;
    created_at: string;
    updated_at: string;
    eligibility: Array<string>;
    isPhoneFirst: boolean;
    isPasswordSet: boolean;
    signupPageType: string;
    onboardedUserCallBackRequested: string[];
    lastTrackSelected: string | null;
    preCourseCurrentStage: string | null;
    clickId: string;
    eligibilityTestScore: number | null;
  } | null;
  homePageLoading: boolean;
  homePageError: Record<string, unknown> | null;
  batchManagerTasksData: Array<BmTaskInterface> | null;
  batchManagerAllTasksData: Array<BmTaskInterface> | null;
  batchManagerTasksDetailsData: BmTaskDetailsInterface | null;
}
