import { Box } from "@mui/material";
import { type FC, type ReactElement, type ReactNode } from "react";

import useIsMobile from "../../hooks/useIsMobile";

interface LayoutInterface {
  children: ReactElement | ReactNode;
  isNavOpen: boolean;
  setNavState: (state: boolean) => void;
}
const Layout: FC<LayoutInterface> = ({ children, isNavOpen, setNavState }) => {
  const isMobile = useIsMobile();

  return (
    <Box className={isMobile ? "mobile-layout" : "desktop-layout"}>
      <Box className={"main-content"}>{children}</Box>
    </Box>
  );
};

export default Layout;
