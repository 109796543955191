import { ModalClose, Sheet } from "@mui/joy";
import { Modal } from "@mui/material";
import { type FC } from "react";
import { ModuleJourneyStateType } from "./ActivityData";

type ModuleJourneyPropsType = {
  isOpen: boolean;
  onClose: () => void;
  moduleHistory?: ModuleJourneyStateType;
};

export const ModuleJourney: FC<ModuleJourneyPropsType> = ({
  isOpen,
  onClose,
  moduleHistory,
}) => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 950,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          onClick={onClose}
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        {moduleHistory ? (
          <div className="modal-content-wrapper">
            <h3 style={{ textAlign: "center", marginBottom: 12 }}>
              Module History
            </h3>
            <label>Attendance Percentage:</label>{" "}
            <span>{moduleHistory.attendancePercentage}%</span>
            <br />
            <label>Assignment Percentage:</label>{" "}
            <span>{moduleHistory.assignmentPercentage}%</span>
            <br />
            <label>Percentage in Gym Questions:</label>{" "}
            <span>
              {Math.round(
                (moduleHistory.gymQuestionsStats.done /
                  moduleHistory.gymQuestionsStats.total) *
                  100
              )}
              %
            </span>
            <br />
            {moduleHistory.percentageInModuleTest ? (
              <>
                <label>Percentage in Module Test:</label>{" "}
                <span>{moduleHistory.percentageInModuleTest}%</span>
                <br />
              </>
            ) : null}
            <label>Percentage in Contests:</label>{" "}
            <span>{moduleHistory.numberOfContests.percentageAttempted}%</span>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </Sheet>
    </Modal>
  );
};
