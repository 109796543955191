import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBatchManagerInfo,
  fetchBmAllTasksData,
  fetchBmTaskDetailsData,
  fetchBmTasksData,
} from "./home.thunk";
import { HomeInterface } from "./home.type";

const initialState: HomeInterface = {
  batchManagerDetails: null,
  homePageLoading: false,
  homePageError: null,
  batchManagerTasksData: null,
  batchManagerAllTasksData: null,
  batchManagerTasksDetailsData: null,
};

export const HomeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    //Batch Manager user info
    [fetchBatchManagerInfo.pending.type]: (
      state,
      action: ReturnType<typeof fetchBatchManagerInfo.pending>
    ) => {
      state.homePageLoading = true;
    },
    [fetchBatchManagerInfo.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchBatchManagerInfo.fulfilled>
    ) => {
      state.batchManagerDetails = action.payload;
      state.homePageLoading = false;
    },
    [fetchBatchManagerInfo.rejected.type]: (
      state,
      action: ReturnType<typeof fetchBatchManagerInfo.rejected>
    ) => {
      state.homePageLoading = false;
      state.homePageError = {
        error: action.payload,
        message: "Batch Manager User Info Fetch Failed",
      };
    },

    // Batch Manager  Tasks Data

    [fetchBmTasksData.pending.type]: (
      state,
      action: ReturnType<typeof fetchBmTasksData.pending>
    ) => {
      state.homePageLoading = true;
    },
    [fetchBmTasksData.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchBmTasksData.fulfilled>
    ) => {
      state.batchManagerTasksData = action.payload.map((task: any) => {
        task.id = task.taskId;
        return task;
      });
      state.homePageLoading = false;
    },
    [fetchBmTasksData.rejected.type]: (
      state,
      action: ReturnType<typeof fetchBmTasksData.rejected>
    ) => {
      state.homePageLoading = false;
      state.homePageError = {
        error: action.payload,
        message: "Batch Manager Tasks Data Fetch Failed",
      };
    },

    // Batch Manager All Tasks Data

    [fetchBmAllTasksData.pending.type]: (
      state,
      action: ReturnType<typeof fetchBmAllTasksData.pending>
    ) => {
      state.homePageLoading = true;
    },
    [fetchBmAllTasksData.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchBmAllTasksData.fulfilled>
    ) => {
      state.batchManagerAllTasksData = action.payload.map((task: any) => {
        task.id = task.taskId;
        return task;
      });
      state.homePageLoading = false;
    },
    [fetchBmAllTasksData.rejected.type]: (
      state,
      action: ReturnType<typeof fetchBmAllTasksData.rejected>
    ) => {
      state.homePageLoading = false;
      state.homePageError = {
        error: action.payload,
        message: "Batch Manager All Tasks Data Fetch Failed",
      };
    },

    // Batch Manager All Tasks Data

    [fetchBmTaskDetailsData.pending.type]: (
      state,
      action: ReturnType<typeof fetchBmTaskDetailsData.pending>
    ) => {
      state.homePageLoading = true;
      state.batchManagerTasksDetailsData = null;
    },

    [fetchBmTaskDetailsData.fulfilled.type]: (
      state,
      action: ReturnType<typeof fetchBmTaskDetailsData.fulfilled>
    ) => {
      state.batchManagerTasksDetailsData = action.payload;
      state.homePageLoading = false;
    },

    [fetchBmTaskDetailsData.rejected.type]: (
      state,
      action: ReturnType<typeof fetchBmTaskDetailsData.rejected>
    ) => {
      state.homePageLoading = false;
      state.homePageError = {
        error: action.payload,
        message: "Batch Manager Tasks Details Data Fetch Failed",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const HomeActions = HomeSlice.actions;
export const HomeReducer = HomeSlice.reducer;
