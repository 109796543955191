import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkHtml from "remark-html";

export const MarkDownView = ({ statement }: { statement: string }) => (
  <ReactMarkdown
    className={"markdown-body"}
    rehypePlugins={[[rehypeHighlight], [rehypeRaw]]}
    remarkPlugins={[[remarkGfm], [remarkHtml as any]]}
  >
    {statement}
  </ReactMarkdown>
);
