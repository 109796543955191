import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";

import { getAuthCookie } from "../utils/authCookie";
import { singleton } from "../utils/singleton";

export type HandleStatusCodeType = Record<number, (error: AxiosError) => void>;

export const ApiFailureErrorMessage = "Failure in API call";

@singleton
export class HttpService {
  async get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return await this.request<T>({
      method: "GET",
      url,
      ...config,
    });
  }

  async post<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return await this.request<T>({
      method: "POST",
      url,
      ...config,
    });
  }

  async patch<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({
      method: "PATCH",
      url,
      ...config,
    });
  }

  async getWithoutToken<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return await this.requestWithoutToken<T>({
      method: "GET",
      url,
      ...config,
    });
  }

  async postWithoutToken<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return await this.requestWithoutToken<T>({
      method: "POST",
      url,
      ...config,
    });
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const token: string | undefined = getAuthCookie();
    const defaultRequestInfo: AxiosRequestConfig = {
      headers: {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        "acciojobs-token": token,
      },
    };
    return await axios.request<T>({
      ...defaultRequestInfo,
      ...config,
      headers: {
        ...defaultRequestInfo.headers,
        ...config.headers,
      },
    });
  }

  async requestWithoutToken<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const defaultRequestInfo: AxiosRequestConfig = {
      headers: {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      },
    };
    return await axios.request<T>({
      ...defaultRequestInfo,
      ...config,
      headers: {
        ...defaultRequestInfo.headers,
        ...config.headers,
      },
    });
  }

  onHttpErrorStatus(
    error: AxiosError,
    handleStatusCode: HandleStatusCodeType
  ): void {
    if (error.response?.status) {
      const statusCode = error.response.status;
      const handleStatusCodeFn = handleStatusCode[statusCode];

      if (typeof handleStatusCodeFn === "function") {
        handleStatusCodeFn(error);
      }
    }
  }
}
