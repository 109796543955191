import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React from "react";

const DataGridComponent: React.FC<{
  rows: any[];
  columns: GridColDef[];
  onRowClick?: (e: any) => void;
  pageSizeOptions?: number[];
  pageSizeVal?: number;
}> = ({ rows, columns, onRowClick }) => {
  return (
    <div className="data-grid-wrapper">
      <DataGrid
        onRowClick={onRowClick}
        rows={rows}
        columns={columns}
        autoHeight
        slots={{
          toolbar: GridToolbar,
        }}
        isCellEditable={(item: any) => item.id === 0}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        rowSelection
        pageSizeOptions={[10, 50, 100]}
        style={{ marginTop: 10 }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default DataGridComponent;
