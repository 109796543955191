import { Route, Routes } from "react-router-dom";

import FollowUpPage from "../page/FollowUpPage";
import Home from "../page/Home";
import ViewAllTask from "../page/ViewAllTask";

const AppRouter = () => {
  const RouteMap = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/follow-up",
      element: <FollowUpPage />,
    },
    // {
    //   path: "/follow-up-content",
    //   element: <FollowUpPageContent />,
    // },
    {
      path: "/all-tasks",
      element: <ViewAllTask />,
    },
  ];

  return (
    <Routes>
      <Route path={"*"} element={<></>} />

      {RouteMap.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}

      <Route path={"/locked"} element={<></>} />
    </Routes>
  );
};

export default AppRouter;
