import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

import { notifyError } from "../components/notification";

import {
  getAuthCookie,
  removeAuthCookie,
  setAuthCookie,
} from "../utils/authCookie";

interface useAuthProps {
  allowedRoles?: Array<string>;
  callBack: () => void;
}

export const logout = (domain?: string) => {
  removeAuthCookie({ domain });
  window.open("https://authentication.acciojob.com", "_self");
};

const useAuthentication = ({ allowedRoles, callBack }: useAuthProps) => {
  const [authenticated, setAuthenticated] = useState(false);

  const isAccessAllowed = (roles: Array<string>, userRole: string) => {
    if (roles.length > 0) {
      return roles.includes(userRole);
    } else return true;
  };

  const performActionOnTokenPresent = (token: string, domain?: string) => {
    setAuthCookie({ token, domain });
    const decodedObject: unknown = jwt_decode(token);
    // @ts-expect-error: This works
    const userRole = decodedObject?.roles[0];
    const isUserAllowed = isAccessAllowed(allowedRoles ?? [], userRole);

    if (isUserAllowed) {
      setAuthenticated(true);
      if (callBack) callBack();
    } else logout(domain);
  };

  const authenticationCheck = async () => {
    const url = window.location.hostname;

    const domain = url.split(".").pop();

    // Local Development
    // if (process.env.REACT_APP_TOKEN) {
    //   const localToken = process.env.REACT_APP_TOKEN;
    //   try {
    //     if (localToken) {
    //       performActionOnTokenPresent(localToken);
    //     } else {
    //       alert("localToken is undefined");
    //     }
    //   } catch (err) {
    //     console.log("TOKEN ERROR", err);
    //     alert(
    //       "There is some error with the Local Development token please check console for => TOKEN ERROR"
    //     );
    //   }
    // } else

    const userAccessToken = getAuthCookie();
    try {
      if (userAccessToken) {
        performActionOnTokenPresent(userAccessToken, domain);
      } else {
        notifyError("You have been logged, please login again");
        logout(domain);
      }
    } catch (err) {
      console.log("TOKEN ERROR", err);
      notifyError("You have been logged, please login again");
      logout(domain);
    }
  };

  useEffect(() => {
    authenticationCheck();
  }, []);

  return authenticated;
};

export default useAuthentication;
