import { Modal, ModalClose, Sheet } from "@mui/joy";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useMemo, useState, type FC } from "react";
import { BsFillClipboard2CheckFill } from "react-icons/bs";
import { fetchCourseDetails } from "../../redux/followUp/followUp.thunk";
import { CourseDetailsType } from "../../redux/followUp/followUp.type";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import DataGridComponent from "../shared/DataGrid";
import Heatmap from "../shared/HeatMap";
import FeedBackDataTreeView from "./FeedBackTreeView";

type CourseDetailsPropsType = {
  userId: string;
};

export const CourseDetails: FC<CourseDetailsPropsType> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { courseDetails } = useAppSelector((state) => state.followUp);

  const [clickedContestRow, setClickedContestRow] =
    useState<CourseDetailsType["latestModule"]["courseContests"][0]>();
  const [clickedMockRow, setClickedMockRow] =
    useState<CourseDetailsType["mocks"][0]>();

  useEffect(() => {
    if (!userId) return;

    dispatch(fetchCourseDetails({ userId }));
  }, [userId]);

  const contestColumns = useMemo(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "startTime",
        headerName: "Date",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "difficulty",
        headerName: "Difficulty",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "score",
        headerName: "Score",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "userRank",
        headerName: "Rank",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
    ] as GridColDef[];
  }, []);

  const contestRows = useMemo(() => {
    return (
      courseDetails?.latestModule.courseContests.map((contest) => {
        return {
          score: `${contest.userScore} / ${contest.totalScore}`,
          ...contest,
        };
      }) ?? []
    );
  }, [courseDetails]);

  const mockColumns = useMemo(() => {
    return [
      { field: "id", headerName: "S No." },
      {
        field: "sessionType",
        headerName: "Session Type",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "sessionDate",
        headerName: "Date",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "score",
        headerName: "Score",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "mentorName",
        headerName: "Mentor",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
    ] as GridColDef[];
  }, []);

  const mockRows = useMemo(() => {
    return (
      courseDetails?.mocks.map((mock, index) => {
        return {
          id: index + 1,
          status: mock.isCancelled
            ? "Cancelled"
            : mock.isRescheduled
            ? "Rescheduled"
            : null,
          ...mock,
        };
      }) ?? []
    );
  }, [courseDetails]);

  console.log({ clickedMockRow });

  if (!courseDetails) return null;

  return (
    <div className="course-details">
      <div className="latest-module">
        <div className="card">
          <div>Current Module</div>
          <div>{courseDetails.latestModule.latestCourse.title}</div>
        </div>
        <div className="card">
          <div>Batch Age</div>
          <div>{courseDetails.latestModule.batchAge}</div>
        </div>
        <div className="card">
          <div>Modules Completed</div>
          <div>{courseDetails.latestModule.modulesCompleted}</div>
        </div>
      </div>
      <div className="gym-details">
        <div
          style={{
            display: "flex",
          }}
        >
          <Heatmap data={courseDetails.gymDetails.heatMap} legend />
        </div>
        <div className="cards">
          <div className="card">
            <div>
              <span
                className="icon"
                style={{
                  background: "#ffe6d5",
                }}
              >
                🔥
              </span>
              <span>{courseDetails.gymDetails.streak}</span>
            </div>
            <div>Streak Count</div>
          </div>
          <div className="card">
            <div>
              <span
                className="icon"
                style={{
                  background: "#d1e9ff",
                  color: "#2e90fa",
                }}
              >
                <BsFillClipboard2CheckFill />
              </span>
              <span>{courseDetails.gymDetails.totalQuestionsSolved}</span>
            </div>
            <div>Total Questions Solved</div>
          </div>
        </div>
      </div>
      <div className="contests">
        <h3>Contests Given</h3>
        <DataGridComponent
          columns={contestColumns}
          rows={contestRows}
          onRowClick={({ row }) => setClickedContestRow(row)}
        />
        <Modal
          aria-pledby="modal-title"
          aria-describedby="modal-desc"
          open={!!clickedContestRow}
          onClose={() => setClickedContestRow(undefined)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "calc(100vw - 32px)",
              minHeight: "20vh",
              maxHeight: "calc(100vh - 32px)",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              display: "flex",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.surface",
              }}
            />
            <div className="modal-content-wrapper modal-contest-content-wrapper">
              <h3>Contest Analysis</h3>
              <div className="rows">
                <label>Name:</label>
                <div>{clickedContestRow?.name}</div>
              </div>
              <div className="rows">
                <label>Date:</label>
                <div>
                  {moment(clickedContestRow?.startTime).format(
                    "DD MMM YYYY, hh:mm a"
                  )}
                </div>
              </div>
              <div className="rows">
                <label>Difficulty:</label>
                <div>{clickedContestRow?.difficulty}</div>
              </div>
              <div className="rows">
                <label>Duration:</label>
                <div>{clickedContestRow?.durationInMinutes}</div>
              </div>
              <div className="rows">
                <label>Attempt Time:</label>
                <div>
                  {moment(clickedContestRow?.attemptStartTime).format(
                    "DD MMM YYYY, hh:mm a"
                  )}
                </div>
              </div>
              <div className="rows">
                <label>Cheat Detected:</label>
                <div>{String(clickedContestRow?.isCheatingDetected)}</div>
              </div>
              <div className="rows">
                <label>Plag Detected:</label>
                <div>{String(clickedContestRow?.isPlagDetected)}</div>
              </div>
              <div className="cards">
                <div className="card">
                  <div>Score</div>
                  <div>
                    {clickedContestRow?.userScore} /{" "}
                    {clickedContestRow?.totalScore}
                  </div>
                </div>
                <div className="card">
                  <div>Rank</div>
                  <div>{clickedContestRow?.userRank}</div>
                </div>
                <div className="card">
                  <div>Code</div>
                  <div>{clickedContestRow?.codingQuestionCount}</div>
                </div>
                <div className="card">
                  <div>Conceptual</div>
                  <div>{clickedContestRow?.conceptualQuestionCount}</div>
                </div>
                <div className="card">
                  <div>Web Dev</div>
                  <div>{clickedContestRow?.webDevQuestionCount}</div>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>
      </div>
      <div className="mocks">
        <h3>Mocks Given</h3>
        <DataGridComponent
          columns={mockColumns}
          rows={mockRows}
          onRowClick={({ row }) => setClickedMockRow(row)}
        />
        <Modal
          aria-pledby="modal-title"
          aria-describedby="modal-desc"
          open={!!clickedMockRow}
          onClose={() => setClickedMockRow(undefined)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 950,
              minWidth: 600,
              minHeight: "20vh",
              maxHeight: "calc(100vh - 32px)",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.surface",
              }}
            />
            <div className="modal-content-wrapper">
              <h3>Feedback</h3>
              {Array.isArray(clickedMockRow?.mentorFeedback) &&
              (clickedMockRow?.mentorFeedback?.length || 0) === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No feedback given
                </div>
              ) : (
                <FeedBackDataTreeView
                  data={clickedMockRow?.mentorFeedback}
                  expanded
                />
              )}
            </div>
          </Sheet>
        </Modal>
      </div>
    </div>
  );
};
