import { createSlice } from "@reduxjs/toolkit";

import type { UserSliceInterface } from "./user.type";

import { fetchUserReport } from "./user.thunk";

const initialState: UserSliceInterface = {
	user: null,
	userName: "username",
	userReportCard: null,
	userStateLoading: false,
	latestTrackSelection: null,
	isCrispConfigured: false,

};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setLatestTrackSelection: (state, action) => {
			state.latestTrackSelection =
				action.payload as UserSliceInterface["latestTrackSelection"];
			if (action.payload === "data_analytics") {
				window.open(
					"https://data-analytics-precourse.acciojob.com",
					"_self"
				);
			}
		},		markIsCrispConfigured(state, action) {
			state.isCrispConfigured = action.payload;
		},
},
	extraReducers: {
		[fetchUserReport.pending.type]: state => {
			state.userStateLoading = true;
		},
		[fetchUserReport.fulfilled.type]: (
			state,
			action: ReturnType<typeof fetchUserReport.fulfilled>
		) => {
			state.user = action.payload
				?.user as UserSliceInterface["user"];
			state.userReportCard = action.payload
				?.reportCard as UserSliceInterface["userReportCard"];
			state.latestTrackSelection = state.user
				?.lastTrackSelected as UserSliceInterface["latestTrackSelection"];
			state.userStateLoading = false;
			state.userName = state.user
				?.firstName as UserSliceInterface["userName"];
			if (state.latestTrackSelection === "data_analytics") {
				// window.open("https://data-analytics-precourse.acciojob.com", "_self");
			}
		},
		[fetchUserReport.rejected.type]: (
			state,
			action: ReturnType<typeof fetchUserReport.rejected>
		) => {
			state.userStateLoading = false;
			state.error = {
				code: Number(action.error.code),
				message: action.error.message,
			};
		},
	},
});

// Action creators are generated for each case reducer function
export const UserActions = userSlice.actions;
export const UserReducer = userSlice.reducer;
