import Cookies from "js-cookie";

interface SetAuthCookieArgsInterface {
  token: string;
  domain?: string;
  expires?: number;
}

const authCookieName = "bm-acciojobs-token";

export const setAuthCookie = ({
  token,
  domain,
  expires = 365,
}: SetAuthCookieArgsInterface) => {
  if (!domain) {
    domain = "acciojob.com";
  }
  Cookies.set(authCookieName, token, {
    domain,
    expires,
    secure: true,
  });
};

export const getAuthCookie = () => Cookies.get(authCookieName);

export const removeAuthCookie = ({ domain }: { domain?: string }) => {
  if (!domain) {
    domain = "acciojob.com";
  }

  Cookies.remove(authCookieName, {
    domain,
  });
};
