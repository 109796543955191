import { BmTaskDetailsInterface } from "../home/home.type";

export interface FollowUpInterface {
  regularFollowUpTasksInfo: Array<FollowUpTasksInfoInterface>;
  followUpTasksInfo?: {
    studentData: Array<FollowUpTasksInfoInterface>;
    inProgressStudentCount: number;
    toDoStudentCount: number;
    totalStudentCount: number;
  } | null;
  followUpPageLoading: boolean;
  followUpPageError: any;
  otherInfo?: OtherInfoInterface | null;
  placementDetails?: PlacementDetailsInterface | null;
  courseDetails?: CourseDetailsType | null;
  adhocTasks: Array<{
    id: string;
    name: string;
  }>;
  todaysFollowUpTaskCount: number;
  taskDetails?: BmTaskDetailsInterface;
}

export type CourseDetailsType = {
  latestModule: {
    batchAge: number;
    modulesCompleted: number;
    latestCourse: {
      title: string;
    };
    courseContests: Array<{
      id: string;
      name: string;
      difficulty: string;
      userScore: number;
      totalScore: number;
      type: string;
      userRank: number;
      startTime: string;
      isPlagDetected: boolean;
      isCheatingDetected: boolean;
      durationInMinutes: number;
      attemptStartTime: string;
      conceptualQuestionCount: number;
      codingQuestionCount: number;
      webDevQuestionCount: number;
    }>;
  };
  gymDetails: {
    heatMap: Array<{
      day: number;
      firstsolveddate: string;
      intensity: number;
      score: number;
    }>;
    streak: number;
    totalQuestionsSolved: number;
  };

  mocks: Array<{
    sessionType: string;
    sessionDate: string;
    score: number;
    mentorName: string;
    mentorFeedback: any;
    studentFeedback: any;
    isCancelled: boolean;
    isRescheduled: boolean;
    sessionId: string;
  }>;
};

export interface FollowUpTasksInfoInterface {
  lastFollowUpDate: string;
  latestCourse: string;
  pendingSubtasksCount: number;
  repeatCount: number;
  totalSubtaskCount: number;
  userEmail: string;
  userId: string;
  userName: string;
  userPhoneNumber: string;
}

export interface PlacementDetailsInterface {
  jobCardDashboardDetails: {
    Applied: number;
    Eligible: number;
    ["Not Applied"]: number;
    Ongoing: number;
    Rejected: number;
  };
  isBlackListed: {
    blacklisted: boolean;
    blacklistedReason: string;
  };
  daysSincePlacementReady?: {
    daysSinceFirstMock: number;
    daysSinceLastMock: number;
  };
  jobCardsForUser: Array<{
    companyName: string;
    driveName: string;
    trackType: string;
    applicationStage: string;
    ctc: number;
    rolesOffered: Array<string>;
    locations: Array<string>;
    updatedAt: string;
    reasonOfRejection: string;
  }>;
  placementReadyMocks: Array<{
    sessionType: string;
    date: string;
    score: string;
    mentor: string;
    feedback: Record<string, string | number>;
  }>;
  isProfileLocked: boolean;
  isResumeLocked: boolean;
  isResumeVerified: boolean;
  placementStatus?: string;
  resumeLink?: string;
  resumeFeedback?: null;
  profileStatus?: string;
  companyFacingRoundOngoing: number;
}

export interface OtherInfoInterface {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  gender?: string;
  dob?: string;
  fatherName?: string;
  homeState?: string;
  languages?: string[];
  parentsContact?: string;
  class10Percentage?: string;
  class12Percentage?: string;
  graduationYear?: number;
  graduationDegree?: string;
  graduationPercentage?: string;
  graduationCollege?: string;
  department?: string;
  leetcodeProfile?: string;
  githubUsername?: string;
  employee?: string;
  pgCollegeName?: string;
  postGraduationYear?: number;
  postGraduationPercentage?: string;
  pgCollegeDegree?: string;
  pgCollegeDepartment?: string;
  collegeBacklogs?: string;
  preferredCodingLanguage?: string;
  codingSkills?: string[];
  githubProfileLink?: string;
  currentState?: string;
  currentDistrict?: string;
  workExperience?: string;
  currentCompany?: string;
  currentCtc?: string;
  noticePeriod?: string;
  resume?: string;
  minSalaryGuarantee?: string;
  enrollmentMethod?: string;
  selectedTrack?: string;
}

export enum CurrentStatusEnum {
  ACTIVE = "ACTIVE",
  MONTH_BREAK = "MONTH BREAK",
  INACTIVE = "INACTIVE",
  PAUSED = "PAUSED",
  PLACED = "PLACED",
}

export enum CurrentSubStatusEnum {
  ACHIEVERS = "ACHIEVERS",
  PAUSED = "PAUSED",
  PLACED_THROUGH_US = "PLACED THROUGH US",
  EXPLORERS = "EXPLORERS",
  ESCALATED = "ESCALATED",
  AUTOPLACED = "AUTO-PLACED",
  DECEIVERS = "DECEIVERS",
  DROPPED_OFF = "DROPPED OFF",
  INTERNSHIP_THROUGH_US = "INTERNSHIP THROUGH US",
  UNRESPONSIVE = "UNRESPONSIVE",
  INTERNSHIP_ON_ITS_OWN = "INTERNSHIP ON ITS OWN",
}

export enum CallAttemptStatusEnum {
  CALL_PICKED = "CALL PICKED",
  MARK_AS_NOT_PICKED = "MARK AS NOT PICKED",
  NOT_WILLING_TO_CONNECT = "NOT WILLING TO CONNECT",
}

export enum ModeOfConversationEnum {
  VOICE_CALL = "VOICE CALL",
  MEET = "MEET",
  TEXT = "TEXT",
}
