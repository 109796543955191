import { LabelValueType } from "../types/common.type";

export const FollowUpPageTabs: Array<LabelValueType> = [
  {
    label: "Course Details",
    value: "courseDetails",
  },
  {
    label: "Placement Details",
    value: "placementDetails",
  },
  // {
  //   label: "All Mocks given",
  //   value: "allMocks",
  // },
  // {
  //   label: "All Contests",
  //   value: "allContests",
  // },
  // {
  //   label: "All Projects",
  //   value: "allProjects",
  // },
  // {
  //   label: "Other info",
  //   value: "otherInfo",
  // },
];
