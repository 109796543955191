import { Tab, Tabs } from "@mui/material";
import { type FC, type ReactNode } from "react";
import { LabelValueType } from "../../types/common.type";

type TabPanelPropsType = {
  tabs: Array<LabelValueType>;
  getTabPanelComponent: (tabValue: string) => ReactNode;
  selectedTab: string | null | undefined;
  setSelectedTab?: (tab: string) => void;
  name: string;
};

export const TabPanel: FC<TabPanelPropsType> = ({
  getTabPanelComponent,
  selectedTab,
  setSelectedTab,
  tabs,
  name,
}) => {
  const a11yProps = (index: number) => {
    return {
      id: `${name}-simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <div className={"tabs"}>
        <Tabs
          value={selectedTab}
          aria-label={"tab-panel"}
          onChange={(_, value) => {
            setSelectedTab?.(value);
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={tab.value}
                label={tab.label}
                className={"tab"}
                value={tab.value}
                icon={tab.icon}
                iconPosition="start"
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </div>

      {tabs.map((tab, index) => {
        return (
          <div
            key={tab.value}
            role="tabpanel"
            hidden={selectedTab !== tab.value}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`${name}-simple-tab-${index}`}
            className="tab-panel"
          >
            {getTabPanelComponent(tab.value)}
          </div>
        );
      })}
    </>
  );
};
