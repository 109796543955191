import { singleton } from "../utils/singleton";

import { ApiFailureErrorMessage, HttpService } from "./http.service";

@singleton
export class UserService {
  private endpoint = process.env.REACT_APP_ROOT;
  private httpService = new HttpService();

  async getUser(): Promise<Record<string, unknown>> {
    const url = `${this.endpoint}/user-report-card`;
    try {
      const response = await this.httpService.get<unknown>(url);
      return response.data as Record<string, unknown>;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async loginAsUser(email: string) {
    const url = `${this.endpoint}/users/admin/login/only`;
    try {
      const response = await this.httpService.post<unknown>(url, {
        data: {
          phoneNumberOrEmail: email,
        },
      });
      return response.data as Record<string, unknown>;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }

  async submitResumeReview(body: {
    userId: string;
    resumeFeedback?: string | null;
    isResumeVerified: boolean;
  }) {
    const url = `${this.endpoint}/student-personal-details/resume/feedback`;
    try {
      const response = await this.httpService.post<unknown>(url, {
        data: body,
      });
      return response.data as Record<string, unknown>;
    } catch (error) {
      throw new Error(ApiFailureErrorMessage);
    }
  }
}
