import moment from "moment";
import { FC } from "react";
import { FiCircle } from "react-icons/fi";
import { PriorityEnum } from "../../redux/home/home.type";

type TaskDataProps = {
  taskTitle: string;
  deadline: string;
  priority: PriorityEnum;
  owner?: string;
  showIcon?: boolean;
};

export const TaskData: FC<TaskDataProps> = ({
  deadline,
  owner,
  priority,
  taskTitle,
  showIcon = true,
}) => {
  return (
    <div className="task-data-wrapper">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {showIcon ? <FiCircle /> : null}
        <p
          className="text"
          style={
            showIcon
              ? {}
              : {
                  fontSize: 24,
                  fontWeight: 600,
                }
          }
        >
          {taskTitle}
        </p>
      </div>
      <div className="group-chip">
        <span className="chip chip-date">
          {moment(deadline).format("DD-MMM-YYYY")}
        </span>
        <span className={`chip chip-${priority}`}>{priority}</span>
        {owner ? <span className="chip">{owner}</span> : null}
      </div>
    </div>
  );
};
