import { Modal, Sheet } from "@mui/joy";
import ModalClose from "@mui/joy/ModalClose";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Divider } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment/moment";
import { useEffect, useMemo, useState, type FC } from "react";
import { fetchPlacementDetails } from "../../redux/followUp/followUp.thunk";
import { PlacementDetailsInterface } from "../../redux/followUp/followUp.type";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Services } from "../../services";
import { notifyError } from "../notification";
import DataGridComponent from "../shared/DataGrid";
import FeedBackDataTreeView from "./FeedBackTreeView";

type PlacementDetailsPropsType = {
  userId: string;
};

export const PlacementDetails: FC<PlacementDetailsPropsType> = ({ userId }) => {
  const { placementDetails } = useAppSelector((state) => state.followUp);
  const dispatch = useAppDispatch();

  const [selectedFeedback, setSelectedFeedback] =
    useState<Record<string, any>>();

  const [showResumeFeedbackModal, setShowResumeFeedbackModal] =
    useState<boolean>(false);

  const init = () => {
    if (!userId) return;

    dispatch(fetchPlacementDetails({ userId }));
  };

  useEffect(() => {
    init();
  }, [userId]);

  const overviewCardsData = useMemo(() => {
    if (!placementDetails) return null;
    return Object.entries(placementDetails?.jobCardDashboardDetails).map(
      ([key, value]) => {
        return {
          title: key,
          value: value,
        };
      }
    );
  }, [placementDetails]);

  const applicationStageTableColumns = useMemo(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "driveName",
        headerName: "Drive Name",
        flex: 1,
        minWidth: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "trackType",
        headerName: "Track Type",
        flex: 1,
        minWidth: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "applicationStage",
        headerName: "Application Stage",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "ctc",
        headerName: "CTC",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: true,
      },
      {
        field: "rolesOffered",
        headerName: "Roles Offered",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        valueGetter: (params) => {
          return params.value ? (params.value as string[]).join(", ") : "N/A";
        },
      },
      {
        field: "locations",
        headerName: "Locations",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        valueGetter: (params) => {
          return params.value ? (params.value as string[]).join(", ") : "N/A";
        },
      },
      {
        field: "reasonOfRejection",
        headerName: "Reason of Rejection",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
    ] as GridColDef[];
  }, []);

  const placementReadinessMockColumns = useMemo(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "sessionType",
        headerName: "Session Type",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "date",
        headerName: "date",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: true,
        valueGetter: (params) => {
          return params.value
            ? moment(params.value).format("DD-MMM-YYYY")
            : "N/A";
        },
      },
      {
        field: "score",
        headerName: "Score",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: true,
      },
      {
        field: "mentor",
        headerName: "Mentor",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "feedback",
        headerName: "Feedback",
        flex: 1,
        minWidth: 150,
        editable: false,
        sortable: false,
        searchable: false,
        renderCell: (params) => {
          return (
            <Button
              onClick={() => {
                setSelectedFeedback(params.value);
              }}
            >
              Show
            </Button>
          );
        },
      },
    ] as GridColDef[];
  }, []);

  if (!placementDetails) return null;

  return (
    <div className="follow-up-placement-details">
      <div className="job-drives-overview">
        <h2 className="header">Job Drives Overview</h2>
        <div className="cards">
          {overviewCardsData!.map((cardData) => (
            <div key={cardData.title} className="card">
              <p className="title">{cardData.title}</p>
              <p className="value">{cardData.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="cards">
        {placementDetails.isBlackListed.blacklisted ? (
          <div className="card">
            <h5>Day Since placement ready</h5>
            <h3>BLACKLISTED</h3>
            <p>
              <b>Reason</b>
              <span>{placementDetails.isBlackListed.blacklistedReason}</span>
            </p>
          </div>
        ) : placementDetails.daysSincePlacementReady ? (
          <div className="card">
            <div>
              <h5>Days Since First Mock:</h5>
              <p>
                {placementDetails.daysSincePlacementReady.daysSinceFirstMock}
              </p>
            </div>
            <div>
              <h5>Days Since Last Mock:</h5>
              <p>
                {placementDetails.daysSincePlacementReady.daysSinceLastMock}
              </p>
            </div>
          </div>
        ) : null}
        <div className="card">
          <p className="title">Placement Status</p>
          <p>{placementDetails.placementStatus}</p>
          <Button
            onClick={() => {
              window.open(
                "https://internal.acciojob.com/app/manage-student-placements/update-placement-status-64a56eeb2dc55c5e46d7c634",
                "_blank"
              );
            }}
          >
            Change Status
          </Button>
        </div>
        <div className="card">
          <p className="title">Resume Status</p>
          <p>
            {placementDetails.isResumeLocked
              ? "Locked"
              : placementDetails.isResumeVerified
              ? "Verified"
              : null}
          </p>
          {placementDetails.resumeLink ? (
            <Button
              onClick={() => {
                setShowResumeFeedbackModal(true);
              }}
            >
              View Resume
            </Button>
          ) : null}
        </div>
        <div className="card">
          <p className="title">Profile Status</p>
          <p>{placementDetails.isProfileLocked ? "Locked" : "Unlocked"}</p>
          <Button
            onClick={() => {
              window.open(
                "https://internal.acciojob.com/app/profile-locking/lock-profile-6400943c11a1c526256319f2",
                "_blank"
              );
            }}
          >
            Change
          </Button>
        </div>
      </div>
      <div>
        <h3>Job Cards Application Stages</h3>
        <DataGridComponent
          rows={placementDetails.jobCardsForUser}
          columns={applicationStageTableColumns}
        />
      </div>
      <Divider />
      <div>
        <h3>Placement Readiness & HR Mocks</h3>
        <DataGridComponent
          rows={placementDetails.placementReadyMocks}
          columns={placementReadinessMockColumns}
        />
      </div>
      <Modal
        aria-pledby="modal-title"
        aria-describedby="modal-desc"
        open={!!selectedFeedback}
        onClose={() => setSelectedFeedback(undefined)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 950,
            minWidth: 600,
            minHeight: "50vh",
            maxHeight: "calc(100vh - 32px)",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.surface",
            }}
          />
          <div className="modal-content-wrapper">
            <h3>Feedback</h3>
            <FeedBackDataTreeView data={selectedFeedback!} expanded />
          </div>
        </Sheet>
      </Modal>

      <Modal
        aria-pledby="modal-title"
        aria-describedby="modal-desc"
        open={showResumeFeedbackModal}
        onClose={() => setShowResumeFeedbackModal(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "calc(100vw - 32px)",
            minHeight: "75vh",
            maxHeight: "calc(100vh - 32px)",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            display: "flex",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.surface",
            }}
          />
          <ResumeFeedbackContent
            placementDetails={placementDetails}
            userId={userId}
            onSubmit={init}
          />
        </Sheet>
      </Modal>
    </div>
  );
};

const ResumeFeedbackContent = ({
  placementDetails,
  userId,
  onSubmit,
}: {
  placementDetails: PlacementDetailsInterface;
  userId: string;
  onSubmit?: () => void;
}) => {
  const [feedback, setFeedback] = useState<string>();

  useEffect(() => {
    if (placementDetails.resumeFeedback) {
      setFeedback(placementDetails.resumeFeedback);
    }
  }, [placementDetails]);

  const submitReview = async (isResumeVerified?: boolean) => {
    try {
      await Services.userService.submitResumeReview({
        isResumeVerified: isResumeVerified ?? placementDetails.isResumeVerified,
        resumeFeedback: feedback || placementDetails.resumeFeedback,
        userId,
      });
      onSubmit?.();
    } catch (error) {
      notifyError("Unable to update resume review");
    }
  };

  return (
    <div className="resume-feedback-container">
      <iframe src={placementDetails.resumeLink} title="resume" />
      <div className="right">
        <h3>Review Resume</h3>
        <div className="blue">Resume Checklist</div>
        <div className="resume-checklist">
          <ul>
            <li>
              Does the resume has details like Education, Skills, Career
              Objective/Professional Summary and Projects?
            </li>
            <li>Is the student a working professional?</li>
            <li>
              Are the education details mentioned in the reverse chronological
              order?
            </li>
            <li>
              Has the student added his achievements in the course for example
              (Solved 400+ DSA problems)?
            </li>
            <li>Is the skill section present after the project ?</li>
            <li>
              Is the skill section divided into parts as shown in the image
              below?
            </li>
            <li>Has the student used more than 2 colors in the resume?</li>
            <li>Is same font used throughout the resume?</li>
            <li>Are different font sizes used adequately?</li>
            <li> Is the content of the resume easily readable?</li>
            <li>
              {" "}
              Has the student added details like Contact Number, Email Id,
              LinkedIn and GitHub link?
            </li>
            <li> Are LinkedIn and GitHub links clickable?</li>
            <li>
              {" "}
              Has the student added 1-2 major projects and 1-2 minor projects in
              the resume?
            </li>
            <li>
              {" "}
              Has the student mentioned the tech stacks used in the project?
            </li>
            <li>
              {" "}
              Has the student added link of the project (GitHub for Java
              Projects and Deployed links for the frontend projects)?
            </li>
            <li>
              {" "}
              Has the student added the learnings and usage of the project in
              the project description?
            </li>
          </ul>
        </div>
        <TextArea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <div className="buttons">
          <Button
            onClick={() => {
              submitReview();
            }}
          >
            Submit Review
          </Button>
          {!placementDetails.isResumeVerified ? (
            <Button
              onClick={() => {
                submitReview(true);
              }}
            >
              Verify Resume
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
