import MarkdownEditor from "@uiw/react-markdown-editor";
import { Button } from "antd";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import {
  CurrentStatusEnum,
  CurrentSubStatusEnum,
} from "../../redux/followUp/followUp.type";
import { Services } from "../../services";
import { FollowUpHistory } from "./FollowUpHistory";
import InfoBox from "./InfoBox";
import { ModuleJourney } from "./ModuleJourney";
import { UpcomingEvents } from "./UpcomingEvents";

export type ModuleJourneyStateType = {
  attendancePercentage: number;
  assignmentPercentage: number;
  gymQuestionsStats: {
    done: number;
    total: number;
  };
  percentageInModuleTest: number;
  numberOfContests: {
    attempted: number;
    scheduled: number;
    percentageAttempted: number;
  };
  activityStats: {
    active: number;
    total: number;
  };
};

export type RegularFollowUpTaskStateType = {
  lastFollowUpNotes?: string;
  lastFollowUpDate?: string;
  lastActivitySince?: number;
  latestCurrentStatus?: CurrentStatusEnum;
  latestCurrentSubStatus?: CurrentSubStatusEnum;
};

type ActivityDataProps = {
  regularFollowUpDetails?: RegularFollowUpTaskStateType;
  moduleHistory?: ModuleJourneyStateType;
  userId: string;
};

const ActivityData: FC<ActivityDataProps> = ({
  regularFollowUpDetails,
  userId,
  moduleHistory,
}) => {
  const [showFollowUpHistory, setShowFollowUpHistory] = useState(false);
  const [showModuleJourney, setShowModuleJourney] = useState(false);
  const [showUpcomingEvents, setShowUpComingEvents] = useState(false);

  const [isFollowUpNotesEditable, setIsFollowUpNotesEditable] = useState(false);

  const [notes, setNotes] = useState<string>("");

  const submitRegularFollowUpTask = () => {
    Services.followUpService.addRegularFollowUpTask({
      userId,
      notes: notes,
      isFollowUp: false,
      isUpdate: !!regularFollowUpDetails,
    });
  };

  useEffect(() => {
    if (regularFollowUpDetails?.lastFollowUpNotes)
      setNotes(regularFollowUpDetails?.lastFollowUpNotes);
  }, [regularFollowUpDetails]);

  return (
    <div>
      <div className="all-activity-wrapper">
        <div className="activity-wrapper">
          {regularFollowUpDetails?.lastFollowUpDate ||
          regularFollowUpDetails?.lastActivitySince ? (
            <div className="activity-data-wrapper">
              {regularFollowUpDetails?.lastFollowUpDate ? (
                <InfoBox
                  title="Last Follow-up"
                  data={moment(regularFollowUpDetails?.lastFollowUpDate).format(
                    "DD-MMM-YYYY"
                  )}
                />
              ) : null}
              {regularFollowUpDetails?.lastActivitySince ? (
                <InfoBox
                  title="Last Activity"
                  data={`${regularFollowUpDetails?.lastActivitySince} days ago`}
                />
              ) : null}
            </div>
          ) : null}
          <div className="follow-up-note-wrapper">
            <div className="follow-up-note-header">
              <p className="text">Follow up note</p>
              {!isFollowUpNotesEditable ? (
                <Button
                  type="link"
                  onClick={() => setIsFollowUpNotesEditable(true)}
                >
                  Edit
                </Button>
              ) : null}
            </div>
            <MarkdownEditor
              height="100%"
              editable={isFollowUpNotesEditable}
              contentEditable={isFollowUpNotesEditable}
              value={regularFollowUpDetails?.lastFollowUpNotes ?? ""}
              onChange={(value, viewUpdate) => {
                if (isFollowUpNotesEditable) {
                  setNotes(value);
                }
              }}
            />
            {isFollowUpNotesEditable ? (
              <Button onClick={submitRegularFollowUpTask}>Update</Button>
            ) : null}
          </div>
        </div>
        <div className="follow-up-essentials-wrapper">
          {/* <Button>Live Doubt</Button> */}
          <Button onClick={() => setShowFollowUpHistory(true)}>
            FollowUp History
          </Button>
          <Button onClick={() => setShowUpComingEvents(true)}>
            Upcoming Events
          </Button>
          {/* <Button
            onClick={() => {
              setShowModuleJourney(true);
            }}
          >
            Module Journey
          </Button> */}
        </div>

        {moduleHistory ? (
          <div className="all-info-box-wrapper">
            <InfoBox
              title="Assignments"
              data={`${moduleHistory.assignmentPercentage}%`}
            />
            <InfoBox
              title="Attendance"
              data={`${moduleHistory.attendancePercentage}%`}
            />
            <InfoBox
              title="Contest Attempts"
              data={`${moduleHistory.numberOfContests.percentageAttempted}%`}
            />
            <InfoBox
              title="Gym Avg Count"
              data={`${Math.round(
                (moduleHistory.gymQuestionsStats.done /
                  moduleHistory.gymQuestionsStats.total) *
                  100
              )}%`}
            />
          </div>
        ) : null}

        {/* <div className="taking-pointer-wrapper">
          <p className="text">Taking Pointers</p>
          <div className="data-wrapper">
            <Card
              size="small"
              title={<span>Category : Assignment </span>}
              extra={<span>Date: 31st May </span>}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>

            <Card
              size="small"
              title={<span>Category : Assignment </span>}
              extra={<span>Date: 31st May </span>}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>

            <Card
              size="small"
              title={<span>Category : Assignment </span>}
              extra={<span>Date: 31st May </span>}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>
          </div>
        </div> */}
      </div>
      <FollowUpHistory
        isOpen={showFollowUpHistory}
        onClose={() => setShowFollowUpHistory(false)}
        studentId={userId}
      />
      <UpcomingEvents
        isOpen={showUpcomingEvents}
        onClose={() => setShowUpComingEvents(false)}
        studentId={userId}
      />
      <ModuleJourney
        isOpen={showModuleJourney}
        onClose={() => setShowModuleJourney(false)}
        moduleHistory={moduleHistory}
      />
    </div>
  );
};

export default ActivityData;
