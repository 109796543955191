import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { Accordion, AccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import MarkdownEditor from "@uiw/react-markdown-editor";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  MenuProps,
  Space,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { BiTask } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import {
  CallAttemptStatusEnum,
  CurrentStatusEnum,
  CurrentSubStatusEnum,
  ModeOfConversationEnum,
} from "../../redux/followUp/followUp.type";
import { PriorityEnum } from "../../redux/home/home.type";
import { Services } from "../../services";
import { notifyError, notifySuccess } from "../notification";
import { RegularFollowUpTaskStateType } from "./ActivityData";
import { TaskData } from "./TaskData";

export type FollowUpTaskData = {
  taskId: string;
  taskTitle: string;
  taskDescription: string;
  deadline: string;
  priority: PriorityEnum;
  owner: string;
  taskStatus: string;
  remarks: string;
  subtasks: Array<{
    status: string;
    subtask: {
      description: string;
      id: string;
    };
  }>;
};

export type FollowUpTaskDataState = Array<FollowUpTaskData>;

type RegularFollowUpTasksInput = {
  callAttemptStatus?: string;
  isFollowUp?: boolean;
  currentStatus?: string;
  currentSubStatus?: string;
  modeOfConversation?: string;
  nextFollowUpDate?: string;
  notes?: string;
  duration?: number;
  recordingLink?: string;
};

type FollowUpTaskDataProps = {
  followUpTaskData: FollowUpTaskDataState;
  userId: string;
  regularFollowUpDetails?: RegularFollowUpTaskStateType;
  onSomethingUpdated: () => void;
};

const FollowUpTask: FC<FollowUpTaskDataProps> = ({
  followUpTaskData,
  userId,
  onSomethingUpdated,
  regularFollowUpDetails,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="follow-up-task-wrapper">
        <div className="student-personal-details-top">
          <span className="text">Tasks</span>
          <span className="icon">
            <BiTask size={20} />
          </span>
        </div>
        <hr className="horizontal-line" />

        {followUpTaskData.map((task) => (
          <div className="all-tasks-wrapper">
            <TaskData {...task} />
          </div>
        ))}

        <div className="button-wrapper">
          <Button onClick={() => setOpen(true)}>Mark task status</Button>
        </div>
      </div>

      <Modal
        aria-pledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 950,
            maxHeight: "calc(100vh - 32px)",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.surface",
            }}
          />
          <div className="modal-content-wrapper">
            <AddRegularTaskModalContent
              followUpTaskData={followUpTaskData}
              userId={userId}
              closeModal={() => setOpen(false)}
              onSomethingUpdated={onSomethingUpdated}
              regularFollowUpDetails={regularFollowUpDetails}
            />
          </div>
        </Sheet>
      </Modal>
    </>
  );
};

const AddRegularTaskModalContent: FC<{
  followUpTaskData: FollowUpTaskDataState;
  userId: string;
  closeModal?: () => void;
  onSomethingUpdated: () => void;
  regularFollowUpDetails?: RegularFollowUpTaskStateType;
}> = ({
  followUpTaskData,
  userId,
  closeModal,
  onSomethingUpdated,
  regularFollowUpDetails,
}) => {
  const [regularTaskFormData, setRegularTaskFormData] =
    useState<RegularFollowUpTasksInput>({});

  useEffect(() => {
    setRegularTaskFormData((prev) => ({
      ...prev,
      notes: regularFollowUpDetails?.lastFollowUpNotes,
      currentStatus: regularFollowUpDetails?.latestCurrentStatus,
      currentSubStatus: regularFollowUpDetails?.latestCurrentSubStatus,
    }));
  }, [regularFollowUpDetails]);

  const [showExtendedForm, setShowExtendedForm] = useState(false);

  const handleRegularTaskFormChange = (e: any) => {
    setRegularTaskFormData({
      ...regularTaskFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCallStatusSubmit = async () => {
    try {
      await Services.followUpService.addRegularFollowUpTask({
        callAttemptStatus: regularTaskFormData.callAttemptStatus,
        currentStatus: regularTaskFormData.currentStatus,
        currentSubStatus: regularTaskFormData.currentSubStatus,
        userId,
        isFollowUp: false,
        isUpdate: false,
      });
      onSomethingUpdated();
      if (
        regularTaskFormData.callAttemptStatus ===
        CallAttemptStatusEnum.CALL_PICKED
      )
        setShowExtendedForm(true);
      else closeModal?.();

      notifySuccess("Call status updated successfully");
    } catch (error) {
      notifyError("Error while updating call status");
    }
  };

  const shouldDisableCallStatusButton =
    showExtendedForm ||
    regularTaskFormData.isFollowUp ||
    !(regularTaskFormData.callAttemptStatus ===
    CallAttemptStatusEnum.MARK_AS_NOT_PICKED
      ? regularTaskFormData.callAttemptStatus &&
        regularTaskFormData.currentStatus &&
        regularTaskFormData.currentSubStatus
      : regularTaskFormData.callAttemptStatus);

  const onFinalSubmit = async () => {
    try {
      await Services.followUpService.addRegularFollowUpTask({
        callAttemptStatus: regularTaskFormData.callAttemptStatus,
        currentStatus: regularTaskFormData.currentStatus,
        currentSubStatus: regularTaskFormData.currentSubStatus,
        modeOfConversation: regularTaskFormData.modeOfConversation,
        nextFollowUpDate: regularTaskFormData.nextFollowUpDate,
        notes: regularTaskFormData.notes,
        duration: regularTaskFormData.duration,
        // recordingLink: regularTaskFormData.recordingLink,
        userId,
        isFollowUp: true,
        isUpdate: true,
      });
      onSomethingUpdated();
      closeModal?.();
      notifySuccess("Follow-up task marked successfully");
    } catch (error) {
      notifyError("Error while marking follow-up task");
    }
  };

  const isNotWillingToConnect =
    regularTaskFormData.callAttemptStatus ===
    CallAttemptStatusEnum.NOT_WILLING_TO_CONNECT;

  return (
    <div style={{ minWidth: 550 }}>
      <>
        <h3>Call Status</h3>
        <div
          style={{
            display: "flex",
            gap: 8,
            height: "100%",
          }}
        >
          <RenderEnumDropdown
            disabled={showExtendedForm}
            label="Call Attempt Status"
            name="callAttemptStatus"
            onChange={handleRegularTaskFormChange}
            value={regularTaskFormData.callAttemptStatus}
            options={CallAttemptStatusEnum}
          />

          {regularTaskFormData.callAttemptStatus ===
            CallAttemptStatusEnum.MARK_AS_NOT_PICKED ||
          isNotWillingToConnect ? (
            <>
              <RenderEnumDropdown
                disabled={showExtendedForm || isNotWillingToConnect}
                label="Current Status"
                name="currentStatus"
                onChange={handleRegularTaskFormChange}
                value={regularTaskFormData.currentStatus}
                options={CurrentStatusEnum}
              />

              <RenderEnumDropdown
                disabled={showExtendedForm || isNotWillingToConnect}
                label="Current Tag"
                name="currentSubStatus"
                onChange={handleRegularTaskFormChange}
                value={regularTaskFormData.currentSubStatus}
                options={CurrentSubStatusEnum}
              />
            </>
          ) : null}
        </div>
        <Button
          onClick={handleCallStatusSubmit}
          disabled={shouldDisableCallStatusButton}
        >
          Confirm
        </Button>
        {showExtendedForm ? (
          <>
            <h3
              style={{
                margin: "10px 0px",
              }}
            >
              Student Task Status
            </h3>
            <Accordions
              items={followUpTaskData.map((task) => ({
                id: task.taskId,
                taskName: task.taskTitle,
                priority: task.priority,
                status: task.taskStatus,
                date: moment(task.deadline).format("DD-MMM-YYYY"),
                subtasks: task.subtasks.map((subtask) => ({
                  label: subtask.subtask.description,
                  value: subtask.subtask.id,
                  checked: subtask.status === "COMPLETED",
                })),
                remarks: task.remarks,
              }))}
              userId={userId}
              onSomethingUpdated={onSomethingUpdated}
            />
            <Divider />
            <Checkbox
              value={regularTaskFormData.isFollowUp}
              onChange={(e) => {
                setRegularTaskFormData({
                  ...regularTaskFormData,
                  isFollowUp: e.target.checked,
                });
              }}
            >
              Follow-Up Taken
            </Checkbox>
          </>
        ) : null}

        {regularTaskFormData?.isFollowUp ? (
          <>
            <div
              style={{
                display: "flex",
                gap: 12,
                height: "100%",
              }}
            >
              <div>
                <RenderEnumDropdown
                  label="Mode of Conversation"
                  name="modeOfConversation"
                  onChange={handleRegularTaskFormChange}
                  value={regularTaskFormData.modeOfConversation}
                  options={ModeOfConversationEnum}
                />
                <div>
                  <h5>Duration (in mins)</h5>
                  <Input
                    value={regularTaskFormData.duration}
                    type="number"
                    name="duration"
                    onChange={handleRegularTaskFormChange}
                    min={0}
                  />
                  <br />
                  <br />
                </div>
                <div>
                  <h5>Next follow-up Date</h5>
                  <Space direction="vertical">
                    <DatePicker
                      onChange={(_, dateString) => {
                        setRegularTaskFormData({
                          ...regularTaskFormData,
                          nextFollowUpDate: dateString,
                        });
                      }}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode as HTMLElement;
                      }}
                      picker="date"
                    />
                  </Space>
                  <br />
                  <br />
                </div>
                <RenderEnumDropdown
                  label="Current Status"
                  name="currentStatus"
                  onChange={handleRegularTaskFormChange}
                  value={regularTaskFormData.currentStatus}
                  options={CurrentStatusEnum}
                />

                <RenderEnumDropdown
                  label="Current Tag"
                  name="currentSubStatus"
                  onChange={handleRegularTaskFormChange}
                  value={regularTaskFormData.currentSubStatus}
                  options={CurrentSubStatusEnum}
                />
              </div>
              <div style={{ flex: 1 }}>
                <h3>Follow-Up Note</h3>
                <div
                  style={{
                    width: "100%",
                    height: 350,
                    overflow: "scroll",
                  }}
                >
                  <MarkdownEditor
                    height="100%"
                    width="100%"
                    value={regularTaskFormData.notes ?? ""}
                    onChange={(value, viewUpdate) => {
                      setRegularTaskFormData({
                        ...regularTaskFormData,
                        notes: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <Button onClick={onFinalSubmit}>Submit</Button>
          </>
        ) : null}
      </>
    </div>
  );
};

const RenderEnumDropdown = ({
  name,
  onChange,
  value,
  options,
  label,
  disabled,
}: {
  name: string;
  onChange: any;
  value: any;
  options: any;
  label: string;
  disabled?: boolean;
}) => {
  const items: MenuProps["items"] = Object.entries(options).map(
    ([key, value]) => ({
      label: key.split("_").join(" "),
      key,
      value,
    })
  );
  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <h5 style={{ marginBottom: 4 }}>{label}</h5>
      <Dropdown
        disabled={disabled}
        getPopupContainer={(triggerNode) => {
          return triggerNode.parentNode as HTMLElement;
        }}
        menu={{
          items,
          onClick: (e) => {
            onChange({
              target: {
                name,
                value: options[e.key],
              },
            });
          },
        }}
      >
        <Button>
          <Space>
            {value ? value.split("_").join(" ") : "Select"}
            <FiChevronDown />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default FollowUpTask;

const Accordions: FC<{
  items: any[];
  userId: string;
  onSomethingUpdated: () => void;
}> = ({ items, userId, onSomethingUpdated }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [remarks, setRemarks] = useState<string>("");
  const [selectedSubtasks, setSelectedSubtasks] = useState<
    Array<{
      id: string;
      description: string;
    }>
  >([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setRemarks("");
      setSelectedSubtasks([]);
    };

  const onChangeRemarks = (e: any) => {
    setRemarks(e.target.value);
  };

  useEffect(() => {
    const selectedTask = items.find((item) => item.id == expanded);
    if (items.length && selectedTask) {
      setSelectedSubtasks(
        selectedTask.subtasks
          .filter((subtask: any) => subtask.checked)
          .map((subtask: any) => ({
            id: subtask.value,
            description: subtask.label,
          }))
      );

      setRemarks(selectedTask.remarks || "");
    }
  }, [items, expanded]);

  const onSubmit = async (item: any) => {
    try {
      await Services.followUpService.submitSubtaskSubmit({
        taskId: item.id,
        userId,
        remarks,
        subtasksDone: selectedSubtasks,
        subtasksTodo: item.subtasks
          .filter(
            (subtask: any) =>
              !selectedSubtasks.find(
                (selectedSubtask) => selectedSubtask.id === subtask.value
              )
          )
          .map((subtask: any) => ({
            id: subtask.value,
            description: subtask.label,
          })),
      });
      onSomethingUpdated();
      notifySuccess("Task status updated successfully");
    } catch (error) {
      notifyError("Error while updating task status");
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedSubtasks([
        ...selectedSubtasks,
        {
          id: e.target.value,
          description: e.target.title as string,
        },
      ]);
    } else {
      setSelectedSubtasks(
        selectedSubtasks.filter((item) => item.id !== e.target.value)
      );
    }
  };
  return (
    <div className="accordion-wrapper">
      {items.map((item: any) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <div className="accordion-header">
              <p className="title">{item.taskName}</p>
              <p className="priority">{item.priority}</p>
              <p className="status">{item.status}</p>
              <p className="date">{item.date}</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-content-details">
              <h5>Student Task Status</h5>
              {item.subtasks.length > 0 ? (
                <div className="subtask-wrapper">
                  <Checkbox.Group
                    value={selectedSubtasks?.map((item) => item.id)}
                  >
                    <Space direction="vertical">
                      {item.subtasks.map((subtask: any) => (
                        <Checkbox
                          value={subtask.value}
                          key={subtask.value}
                          title={subtask.label}
                          onChange={handleCheckboxChange}
                        >
                          {subtask.label}
                        </Checkbox>
                      ))}
                    </Space>
                  </Checkbox.Group>
                </div>
              ) : (
                <p>No Subtasks</p>
              )}
              <div>
                <h5>Remarks</h5>
                <TextArea value={remarks} onChange={onChangeRemarks} rows={3} />
              </div>
              <Button onClick={() => onSubmit(item)}>Submit</Button>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
