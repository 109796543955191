import { FollowUpService } from "./followUp.service";
import { HomeService } from "./home.service";
import { HttpService } from "./http.service";
import { UserService } from "./user.service";

export const Services = {
  get httpService(): HttpService {
    return new HttpService();
  },

  get userService(): UserService {
    return new UserService();
  },

  get homeService(): HomeService {
    return new HomeService();
  },

  get followUpService(): FollowUpService {
    return new FollowUpService();
  },
};
